import * as S from './ResultCard.style'
import { IqValue, IqWrapper } from './ResultCard.style'

export interface ResultCardProps {
    img: string
    name: string
    date: string
    iq: number
}

export default function ResultCard({ img, name, iq, date }: ResultCardProps) {
    let percent = (iq / 190) * 100

    if (percent > 100) {
        percent = 100
    }

    return (
        <S.ResultCard>
            <S.Image src={img} />
            <S.Info>
                <S.Name>{name}</S.Name>
                <S.Datetime>{date}</S.Datetime>
            </S.Info>
            <S.GradientGroup>
                <S.GradientInActive percent={percent} />
                <S.GradientActive />
                <IqWrapper percent={percent}>
                    <IqValue>IQ {iq}</IqValue>
                </IqWrapper>
            </S.GradientGroup>
        </S.ResultCard>
    )
}
