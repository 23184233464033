import { styled } from 'styled-components'
import ArrowIcon from '@assets/svg/webapp/compare/compare_arrow.svg'

export const SwipeBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
`

export const RightArrowIcon = styled(ArrowIcon)`
    transform: scaleX(-1);
`

export const Text = styled.p`
    color: rgba(2, 2, 2, 0.5);
    font-family: Avenir400;
    font-size: 14px;
    text-align: center;
    margin: 0;
`
export const CompareWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 -20px;
`
export const CompareInner = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 20px;
    padding: 0 20px 70px;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`

export const CompareImage = styled.img`
    width: 124px;
`
export const UserCard = styled.div<{ $height: number }>`
    height: ${(p) => p.$height}px;
    min-width: 124px;
    position: relative;
`
export const UserCardStat = styled.div`
    padding: 6px 10px;
    background: #fff;
    border-radius: 20px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-family: Avenir600;
    font-size: 12px;
`

export const CardTagWrapper = styled.div`
    position: absolute;
    bottom: 0;
`
