import { Box, Typography } from '@mui/material'

import Lottie from 'react-lottie-player'
import * as animation from '@assets/json/JSON_Cognifi-Logo-Loop.json'

function AccessProduct() {
    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                minHeight: '50vh',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
            }}
        >
            <Lottie
                play
                animationData={animation}
                style={{
                    width: '105px',
                    height: '78px',
                }}
            />
            <Typography
                sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '24px',
                    color: '#020202',
                    marginTop: '20px',
                    lineHeight: 'normal',
                    marginBottom: '5px',
                }}
            >
                Loading...
            </Typography>
            <Typography
                sx={{
                    fontFamily: 'Avenir400',
                    fontSize: '16px',
                    color: 'rgba(2, 2, 2, 0.50)',
                    lineHeight: 'normal',
                    textAlign: 'center',
                }}
            >
                Your brain's about to hit the cognitive gym!
            </Typography>
        </Box>
    )
}

export default AccessProduct
