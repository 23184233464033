import { createContext, ReactNode, useEffect, useState } from 'react';
import { PaymentConfigType, PaymentPlanType } from '@providers/PaymentProvider/PaymentProvider.types';
import { getAdditionalPlan, getPaymentConfig } from '@api/requests';

export const PaymentConfigContext = createContext<null | {
  paymentConfig: null | PaymentConfigType;
  selectedPlan: null | PaymentPlanType;
  setSelectedPlan: React.Dispatch<React.SetStateAction<PaymentPlanType | null>>;
  ultPack: null | PaymentPlanType;
  earlyAccessPlan: null | PaymentPlanType;
  earlyAccessSalePlan: null | PaymentPlanType;
  analysisReportPlan: null | PaymentPlanType;
  analysisReportSalePlan: null | PaymentPlanType;
  offlineAccessSalePlan: null | PaymentPlanType;
  showAdditionalOffersPrice: boolean;
}>(null);

function PaymentConfigProvider({ children }: { children: ReactNode }) {
  const [paymentConfig, setPaymentConfig] = useState<null | PaymentConfigType>(null);

  const [selectedPlan, setSelectedPlan] = useState<PaymentPlanType | null>(null);

  const showAdditionalOffersPrice = new URLSearchParams(window.location.search).get('additional_offer_dc') === 'true';

  const [ultPack, setUltPack] = useState<PaymentPlanType | null>(null);
  const [earlyAccessPlan, setEarlyAccessPlan] = useState<PaymentPlanType | null>(null);
  const [earlyAccessSalePlan, setEarlyAccessSalePlan] = useState<PaymentPlanType | null>(null);
  const [analysisReportPlan, setAnalysisReportPlan] = useState<PaymentPlanType | null>(null);
  const [analysisReportSalePlan, setAnalysisReportSalePlan] = useState<PaymentPlanType | null>(null);
  const [offlineAccessSalePlan, setOfflineAccessSalePlan] = useState<PaymentPlanType | null>(null);

  useEffect(() => {
    getPaymentConfig()
      .then((response) => {
        const fakeDiscounts: Record<string, number> = {
          cognifi_weekly_trial3: 90,
        };

        const convertedPlans = (response.plans ?? []).map((plan) => ({
          ...plan,
          fakeDiscountPercent: fakeDiscounts[plan.id] ?? 30,
        }));

        let paymentConfig = { ...response, plans: convertedPlans };

        if (process.env.NODE_ENV === 'development') {
          paymentConfig = { ...response, plans: convertedPlans, recurly: undefined };
        }

        setPaymentConfig(paymentConfig);
        setSelectedPlan(paymentConfig.plans[0]);
      })
      .catch(console.error);

    const ult_pack = new URLSearchParams(window.location.search).get('ult_pack');
    if (ult_pack) {
      getAdditionalPlan(ult_pack)
        .then((response) => {
          if (response.plans) {
            setUltPack(response.plans[0]);
          }
        })
        .catch(console.error);
    }
    const early_access = new URLSearchParams(window.location.search).get('early_access');
    if (early_access) {
      getAdditionalPlan(early_access)
        .then((response) => {
          if (response.plans) {
            setEarlyAccessPlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }
    const early_access_sale = new URLSearchParams(window.location.search).get('early_access_s');
    if (early_access_sale) {
      getAdditionalPlan(early_access_sale)
        .then((response) => {
          if (response.plans) {
            setEarlyAccessSalePlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }
    const analysis_report = new URLSearchParams(window.location.search).get('analysis_report');
    if (analysis_report) {
      getAdditionalPlan(analysis_report)
        .then((response) => {
          if (response.plans) {
            setAnalysisReportPlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }

    const analysis_report_sale = new URLSearchParams(window.location.search).get('analysis_report_s');
    if (analysis_report_sale) {
      getAdditionalPlan(analysis_report_sale)
        .then((response) => {
          if (response.plans) {
            setAnalysisReportSalePlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }

    const offline_access_sale = new URLSearchParams(window.location.search).get('offline_access_s');
    if (offline_access_sale) {
      getAdditionalPlan(offline_access_sale)
        .then((response) => {
          if (response.plans) {
            setOfflineAccessSalePlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }
  }, []);

  const value = {
    paymentConfig,
    selectedPlan,
    setSelectedPlan,
    showAdditionalOffersPrice,
    ultPack,
    earlyAccessPlan,
    earlyAccessSalePlan,
    analysisReportPlan,
    analysisReportSalePlan,
    offlineAccessSalePlan,
  };

  return <PaymentConfigContext.Provider value={value}>{children}</PaymentConfigContext.Provider>;
}

export default PaymentConfigProvider;
