import {
    PageTitle,
    BasePage,
    PageWrapper,
} from '@components/WebApp/pages/common.style'
import SupportIcon from '@assets/svg/webapp/profile/support.svg'
import TermsIcon from '@assets/svg/webapp/profile/terms.svg'
import PrivacyIcon from '@assets/svg/webapp/profile/privacy.svg'
import CookieIcon from '@assets/svg/webapp/profile/cookie.svg'
import ArrowIcon from '@assets/svg/webapp/profile/arrow.svg'
import LogoutIcon from '@assets/svg/webapp/profile/logout.svg'
import ManageSubs from '@assets/svg/webapp/profile/manage_subs.svg'

import * as S from './Profile.style'

import { createElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../App'
import { CreateCertificateDrawer } from '@components/WebApp/pages/Profile/components'
import { getBaseAssetsUrl } from '@utils/webapp'
import { useWebAppState } from '@components/WebApp/WebApp'
import { APP_CONSTANTS } from '@utils/constants'
import { useUser } from '@providers/UserProvider'
import LogoutModal from './components/LogoutModal'

interface ProfileMainItemProps {
    onClick: () => void
    img: string
    title: string
    text: string
}

export const ProfileMainItem = ({
    onClick,
    img,
    title,
    text,
}: ProfileMainItemProps) => (
    <S.MainItemButton onClick={onClick}>
        <S.MainItemImage src={img} />
        <S.MainItemGroup>
            <S.MainItemButtonTitle>{title}</S.MainItemButtonTitle>
            <S.MainItemButtonText>{text}</S.MainItemButtonText>
        </S.MainItemGroup>
        <ArrowIcon />
    </S.MainItemButton>
)

export default function Profile() {
    const { openIqCertificate, setOpenIqCertificate } =
        useWebAppState()

    const { profile } = useUser()

    const navigate = useNavigate()

    const [isLogoutModalOpen, setLogoutModalOpen] = useState(false)

    const openCertificate = () => {
        if (profile?.certificate_path) {
            const url = getBaseAssetsUrl(profile?.certificate_path)
            console.log(url)
            window.open(url)
        } else {
            setOpenIqCertificate(true)
        }
    }

    return (
        <BasePage>
            <PageWrapper>
                <PageTitle>Profile</PageTitle>

                <S.MainItems>
                    {[
                        {
                            img: '/cognifi/images/webapp/profile/star.webp',
                            title: 'IQ certificate',
                            text: 'Your certificate is ready for one-click download',
                            onClick: openCertificate,
                        },
                        {
                            img: '/cognifi/images/webapp/profile/chart.webp',
                            title: 'Detailed analysis report',
                            text: 'Your detailed IQ test report with insights is ready',
                            onClick: () =>
                                navigate(Routes.WEBAPP_PROFILE_REPORT),
                        },
                    ].map((item, index) => (
                        <ProfileMainItem {...item} key={index} />
                    ))}
                </S.MainItems>

                <S.Items>
                    {[
                        {
                            icon: SupportIcon,
                            text: 'Support',
                            path: `mailto:${APP_CONSTANTS.EMAIL}`,
                        },
                        {
                            icon: TermsIcon,
                            text: 'Terms of use',
                            path: '/cognifi/terms.html',
                        },
                        {
                            icon: PrivacyIcon,
                            text: 'Privacy Policy',
                            path: '/cognifi/privacy.html',
                        },
                        {
                            icon: CookieIcon,
                            text: 'Cookie Policy',
                            path: '/cognifi/terms.html',
                        },
                        {
                            icon: ManageSubs,
                            text: 'Manage subscription',
                            path: '',
                            onClick: () => {
                                navigate(Routes.WEBAPP_MANAGE_SUBSCRIPTIONS)
                            },
                        },
                    ].map((item, index) => (
                        <S.ItemButton
                            key={index}
                            onClick={
                                item.onClick
                                    ? item.onClick
                                    : () => window.open(item.path)
                            }
                        >
                            {createElement(item.icon)}
                            <S.ItemText>{item.text}</S.ItemText>
                            <ArrowIcon />
                        </S.ItemButton>
                    ))}
                </S.Items>

                <S.LogoutButton
                    onClick={() => {
                        setLogoutModalOpen(true)
                    }}
                >
                    <LogoutIcon />
                    Logout
                </S.LogoutButton>
            </PageWrapper>
            <CreateCertificateDrawer
                open={openIqCertificate}
                onClose={() => setOpenIqCertificate(false)}
            />

            {isLogoutModalOpen && (
                <LogoutModal setLogoutModalOpen={setLogoutModalOpen} />
            )}
        </BasePage>
    )
}
