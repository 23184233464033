import React from 'react'
import { Box } from '@mui/material'

function QuizStepBackgroundWrapper({
    children,
}: {
    children: React.ReactNode
}) {
    return (
        <Box
            sx={{
                height: '100%',
                minHeight: '100dvh',
                background: '#F3F2F8',
                display: 'flex',
                flexDirection: 'column',
                backgroundImage:
                    'url(/cognifi/images/gradient_background.webp)',
                backgroundSize: '100% 400px',
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#fff',
            }}
        >
            {children}
        </Box>
    )
}

export default QuizStepBackgroundWrapper
