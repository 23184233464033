import React, { useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { trackEvent } from '@api/requests';
import CommonFooterItems from '@components/common/CommonFooterItems';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { useNavigate } from 'react-router-dom';
import { getPathWithSearchParams } from '@utils/ulr';
import { Routes } from '../../../App';

function Results() {
  const { userLocation, userIqScore } = useQuizState();

  const navigate = useNavigate();

  useEffect(() => {
    trackEvent(`Results screen shown`, userLocation);
  }, []);

  const results = [
    {
      title: 'Personalized IQ certificate',
      sunTitle: 'We’ve created a personalized certificate that is in one click from you, ready to download.',
      btnText: 'Download certificate',
      color: '#6639E6',
      onClick: () => {
        navigate(getPathWithSearchParams(Routes.WEBAPP_PROFILE));
        window.scrollTo(0, 0);
      },
    },
    {
      title: 'Detailed analysis report',
      sunTitle: 'We’ve prepared a detailed report of your IQ test, oddering in-depth insights into your profile',
      btnText: 'View my report',
      color: '#AF5FFF',
      onClick: () => {
        navigate(getPathWithSearchParams(Routes.WEBAPP_PROFILE));
        window.scrollTo(0, 0);
      },
    },
    {
      title: 'Personalized program',
      sunTitle:
        'Your exclusive brain training regime is all set! Click “Start the journey” to access your personalized workouts',
      btnText: 'Start the journey',
      color: '#FF84D5',
      onClick: () => {
        navigate(getPathWithSearchParams(Routes.WEBAPP_TESTS));
        window.scrollTo(0, 0);
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundImage: 'url(images/gradient_background.webp)',
        backgroundSize: '100% 400px',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          width: '100%',
          margin: 'auto',
          maxWidth: '460px',
          padding: '0 20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            '& .logo': {
              margin: '30px auto 0',
            },
          }}
        >
          <img className="logo" width={'70px'} height={'52px'} src={'svg/intro_sign.svg'} alt="logo"></img>
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '24px',
              lineHeight: 'normal',
              color: '#020202',
              textAlign: 'center',
              userSelect: 'none',
              marginTop: '30px',
            }}
          >
            Your IQ score
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10px 0',
            }}
          >
            <img src="svg/results_leaf.svg" alt="leaf"></img>
            <Typography
              sx={{
                fontFamily: 'Avenir700',
                fontSize: '56px',
                lineHeight: 'normal',
                color: '#020202',
                textAlign: 'center',
                userSelect: 'none',
              }}
            >
              {userIqScore}
            </Typography>
            <img style={{ transform: 'scaleX(-1)' }} src="svg/results_leaf.svg" alt="leaf"></img>
          </Box>
          <Typography
            sx={{
              fontFamily: 'Avenir400',
              fontSize: '16px',
              lineHeight: 'normal',
              color: 'rgba(2, 2, 2, 0.50)',
              textAlign: 'center',
              userSelect: 'none',
              marginBottom: '30px',
            }}
          >
            We've prepared a personalized certificate, detailed report, and development plan for you based on your
            results.
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              flexDirection: 'column',
            }}
          >
            {results.map((el) => (
              <Box
                key={el.title}
                sx={{
                  background: '#F3F2F8',
                  borderRadius: '20px',
                  padding: '0 20px 20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <svg width="161" height="85" viewBox="0 0 161 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g mask="url(#mask0_70_7256)">
                    <path d="M80.5 -75V85" stroke={el.color} strokeWidth="10" />
                    <path d="M160.5 5L0.499999 4.99999" stroke={el.color} strokeWidth="10" />
                    <path d="M149.781 45L11.2172 -35" stroke={el.color} strokeWidth="10" />
                    <path d="M120.5 74.282L40.5 -64.2821" stroke={el.color} strokeWidth="10" />
                    <path d="M40.5 74.282L120.5 -64.2821" stroke={el.color} strokeWidth="10" />
                    <path d="M11.2188 45L149.783 -35" stroke={el.color} strokeWidth="10" />
                  </g>
                </svg>

                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontSize: '20px',
                      lineHeight: 'normal',
                      color: '#020202',
                      textAlign: 'center',
                      userSelect: 'none',
                    }}
                  >
                    {el.title}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: 'Avenir400',
                      fontSize: '16px',
                      lineHeight: 'normal',
                      color: '#020202',
                      textAlign: 'center',
                      userSelect: 'none',
                      marginTop: '10px',
                    }}
                  >
                    {el.sunTitle}
                  </Typography>
                </Box>

                <QuizButton bgColor={el.color} onClick={el.onClick} variant={ButtonType.RESULTS} text={el.btnText} />
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              paddingBottom: '20px',
            }}
          >
            <CommonFooterItems />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Results;
