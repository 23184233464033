import React, { useEffect, useState } from 'react';
import { Box, Grow } from '@mui/material';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';

function QuizContainer({ children, padding }: { children: React.ReactNode; padding?: string }) {
  const { step } = useQuizState();
  const [isIn, setIn] = useState(false);

  useEffect(() => {
    setIn(false);
    setTimeout(() => {
      setIn(true);
    }, 50);
  }, [step]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        flexGrow: 1,
      }}
    >
      {isIn && (
        <Grow
          in
          style={{ transformOrigin: 'center top', minHeight: '100%', height: '100%', flexGrow: 1 }}
          timeout={500}
        >
          <Box
            sx={{
              maxWidth: '460px',
              width: '100%',
              flexGrow: 1,
              height: '100%',
              miHeight: '100%',
              padding: padding ?? '0 20px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {children}
          </Box>
        </Grow>
      )}
    </Box>
  );
}

export default QuizContainer;
