import { Box, Typography } from '@mui/material'
import QuizContainer from '../../common/QuizContainer'
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks'
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper'
import Lottie from 'react-lottie-player'
import * as animation from '../../../assets/json/JSON_Cognifi-Logo-Loop.json'
import QuizButton, { ButtonType } from '@components/common/QuizButton'

function Finish() {
    const { setStep } = useQuizState()

    return (
        <QuizStepBackgroundWrapper>
            <QuizContainer>
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Lottie
                        play
                        animationData={animation}
                        style={{
                            width: '70px',
                            height: '52px',
                        }}
                    />
                    <Typography
                        sx={{
                            fontFamily: 'Avenir600',
                            fontWeight: 600,
                            fontSize: '24px',
                            userSelect: 'none',
                            textAlign: 'center',
                            color: '#020202',
                            lineHeight: 'normal',
                            marginTop: '20px',
                            marginBottom: '10px',
                        }}
                    >
                        Ready to see results?
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Avenir400',
                            fontSize: '16px',
                            userSelect: 'none',
                            textAlign: 'center',
                            color: 'rgba(2, 2, 2, 0.50)',
                            lineHeight: 'normal',
                            marginBottom: '20px',
                        }}
                    >
                        You can still go back to change your answers or fill in
                        any skipped questions.
                    </Typography>
                    <QuizButton
                        onClick={() => {
                            setStep((prev) => prev + 1)
                        }}
                        variant={ButtonType.MAIN}
                        text={'View my results'}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'center',
                            width: '100%',
                            margin: '10px 0',
                        }}
                    >
                        <Typography
                            sx={{
                                width: '100%',
                                height: '1px',
                                background: '#E6E7EB',
                            }}
                        />
                        <Typography
                            sx={{
                                fontFamily: 'Avenir400',
                                fontSize: '16px',
                                lineHeight: 'normal',
                                color: 'rgba(2, 2, 2, 0.50)',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            or
                        </Typography>
                        <Typography
                            sx={{
                                width: '100%',
                                height: '1px',
                                background: '#E6E7EB',
                            }}
                        />
                    </Box>
                    <QuizButton
                        onClick={() => {
                            setStep((prev) => prev - 1)
                        }}
                        variant={ButtonType.TRANSPARENT}
                        text={'Back to test'}
                        withImage="/cognifi/svg/arrow_back.svg"
                        imageWidth={24}
                        imageGap
                    />
                </Box>
            </QuizContainer>
        </QuizStepBackgroundWrapper>
    )
}

export default Finish
