import { Box, Link, Typography } from '@mui/material';
import { Routes } from '../../../App';
import CommonFooterItems from '@components/common/CommonFooterItems';

function Footer() {
  return (
    <Box
      sx={{
        maxWidth: '460px',
        margin: 'auto',
        overflow: 'hidden',
        padding: '0 20px',
        marginTop: '40px',
      }}
    >
      <Box
        sx={{
          borderRadius: '16px',
          background: '#F3F2F8',
          padding: '30px 20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img draggable={false} src={'svg/shield.svg'} width={80} alt="camp" />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '24px',
            lineHeight: 'normal',
            textAlign: 'center',
            color: '#000',
            userSelect: 'none',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          30-day money-back guarantee
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '16px',
            lineHeight: 'normal',
            textAlign: 'center',
            userSelect: 'none',
            color: '#000',
            '& a': {
              color: 'var(--primary-color)',
              textDecorationColor: 'var(--primary-color)',
            },
          }}
        >
          With our trial, you`ll gain a comprehensive view of your cardiac health and the means to influence it - in no
          time positively! We are ready to return your money if you feel uncomfortable using the App. Find more about
          applicable limitations in our.
          <br />
          <br />
          Find more about applicable limitations in our{' '}
          <Link target="_blank" href={Routes.TERMS}>
            money-back policy
          </Link>
          .
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0 0px 30px',
          backgroundColor: '#fff',
        }}
      >
        <CommonFooterItems />
      </Box>
    </Box>
  );
}

export default Footer;
