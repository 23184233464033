import { createElement } from 'react'
import * as S from './Navigation.style'
import { NavLink } from 'react-router-dom'

import TestsIcon from '@assets/svg/webapp/navigation/IQ.svg'
import { Routes } from '../../../../App'
import GamesIcon from '@assets/svg/webapp/navigation/Games.svg'
import CompareIcon from '@assets/svg/webapp/navigation/Compare.svg'
import ArticlesIcon from '@assets/svg/webapp/navigation/Articles.svg'
import ProfileIcon from '@assets/svg/webapp/navigation/Profile.svg'
import { isInstagram, isIos } from '@utils/browser'

export default function Navigation() {
    const isInstaBrowser = isIos() && isInstagram

    return (
        <S.NavWrapper $isInstaBrowser={isInstaBrowser}>
            <S.Nav>
                {[
                    {
                        icon: TestsIcon,
                        route: Routes.WEBAPP_TESTS,
                        text: 'IQ tests',
                    },
                    {
                        icon: GamesIcon,
                        route: Routes.WEBAPP_GAMES,
                        text: 'Games',
                    },
                    {
                        icon: CompareIcon,
                        route: Routes.WEBAPP_COMPARE,
                        text: 'Compare',
                    },
                    {
                        icon: ArticlesIcon,
                        route: Routes.WEBAPP_ARTICLES,
                        text: 'Articles',
                    },
                    {
                        icon: ProfileIcon,
                        route: Routes.WEBAPP_PROFILE,
                        text: 'Profile',
                    },
                ].map((item, i) => (
                    <S.NavItem key={i}>
                        <NavLink
                            className={({ isActive }) =>
                                isActive
                                    ? 'nav-link active'
                                    : 'nav-link inactive'
                            }
                            to={item.route}
                        >
                            <S.SvgWrapper>
                                {createElement(item.icon)}
                            </S.SvgWrapper>
                            <S.NavText>{item.text}</S.NavText>
                        </NavLink>
                    </S.NavItem>
                ))}
            </S.Nav>
        </S.NavWrapper>
    )
}
