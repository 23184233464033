import { PropsWithChildren } from 'react'

import { RecurlyOptions } from '@recurly/recurly-js'
import { ElementsProps } from '@recurly/react-recurly'
import { APP_URLS } from '@utils/constants'
import { PaymentConfigType } from '@providers/PaymentProvider/PaymentProvider.types'
import { getAttribution } from '@utils/attribution'
import {
    SubscribeRecurlyError,
    SubscribeRecurlySecure3d,
    SubscribeRecurlySuccess,
} from '@models/recurly'
import { filterFalsyValues, getParamByKey } from '@utils/common'
import { ICertificate, IProfile, IQReport } from '@models/webapp'

export type RecurlyProviderType = RecurlyOptions & PropsWithChildren

export type ElementType = ElementsProps & PropsWithChildren

export interface LocationConfig {
    ip: string | null
    country: string | null
}

export async function fetcher<JSON = unknown>(
    input: RequestInfo,
    init?: RequestInit
): Promise<JSON> {
    const response: Response = await fetch(input, init)

    if (!response.ok || response.status !== 200) {
        const data = await response.json()
        return Promise.reject(data)
    }

    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = await response.json()

    // response.ok is true when res.status is 2xx
    // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
    if (response.ok) {
        return data
    }

    throw new FetchError({
        message: response.statusText,
        response,
        data,
    })
}

export class FetchError extends Error {
    response: Response
    data: {
        message: string
    }

    constructor({
        message,
        response,
        data,
    }: {
        message: string
        response: Response
        data: {
            message: string
        }
    }) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(message)

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FetchError)
        }

        this.name = 'FetchError'
        this.response = response
        this.data = data ?? { message: message }
    }
}

export const getPaymentConfig = async (): Promise<PaymentConfigType> => {
    let url = ''
    let basePath = ''

    if (process.env.NODE_ENV === 'production') {
        url = window.location.origin + '/cognifi/' + window.location.search
    } else {
        url = APP_URLS.DEV_APP_BASE_PATH + '/cognifi/' + window.location.search
        basePath = 'https://subs.galaxylineapp.com'
    }

    return fetcher(basePath + '/cognifi/api/quiz/config', {
        method: 'POST',
        body: JSON.stringify({
            url: url,
        }),
        headers: {
            'Content-Type': 'text/plain',
        },
    })
}

export const getAdditionalPlan = async (
    planName: string
): Promise<PaymentConfigType> => {
    let url = ''
    let basePath = ''

    if (process.env.NODE_ENV === 'production') {
        url = window.location.origin + '/cognifi/' + `?plan=${planName}`
    } else {
        url =
            `${APP_URLS.DEV_APP_BASE_PATH}` + '/cognifi/' + `?plan=${planName}`
        basePath = 'https://subs.galaxylineapp.com'
    }

    return fetcher(basePath + '/cognifi/api/quiz/config', {
        method: 'POST',
        body: JSON.stringify({
            url: url,
        }),
        headers: {
            'Content-Type': 'text/plain',
        },
    })
}

export const getLocationData = async (): Promise<LocationConfig> =>
    fetcher(`${window.location.origin}/remote-addr`, {
        method: 'GET',
    })

export async function createSubscriptionNoUser({
    url,
    email,
    plan_id,
    token_id,
    attribution,
    account,
}: {
    url: string
    email: string
    plan_id: string | undefined
    token_id: string
    attribution: Record<string, any>
    account?: string
}): Promise<
    SubscribeRecurlySuccess | SubscribeRecurlyError | SubscribeRecurlySecure3d
> {
    return fetcher(`${url}/create-subscription-no-user`, {
        method: 'POST',
        body: JSON.stringify({
            plan_id,
            token_id,
            email,
            attribution,
            account,
        }),

        headers: {
            'Content-Type': 'text/plain',
        },
    })
}

export async function finalizeSubscriptionRecurly({
    url,
    subscription_id,
    email,
    firebase_id,
    apple_token,
    attribution,
}: {
    url: string
    subscription_id: string
    email: string
    firebase_id?: string
    apple_token?: string
    attribution: Record<string, any>
}): Promise<
    SubscribeRecurlySuccess | SubscribeRecurlyError | SubscribeRecurlySecure3d
> {
    return fetcher(`${url}/subscription-finalize`, {
        method: 'POST',
        body: JSON.stringify({
            subscription_id,
            email,
            firebase_id,
            apple_token,
            attribution,
        }),
        headers: {
            'Content-Type': 'text/plain',
        },
    })
}

export async function activateSubscriptionRecurly({
    url,
    account_code,
    email,
    firebase_id,
    apple_token,
}: {
    url: string
    account_code?: string
    email: string
    firebase_id?: string
    apple_token?: string
}): Promise<SubscribeRecurlySuccess | SubscribeRecurlyError> {
    return fetcher(`${url}/subscription-activate`, {
        method: 'POST',
        body: JSON.stringify({
            account_code,
            email,
            firebase_id,
            apple_token,
        }),
        headers: {
            'Content-Type': 'text/plain',
        },
    })
}

export const trackEvent = async (
    event: string,
    location?: LocationConfig | null,
    options?: Record<string, any>
): Promise<void> => {
    const userLocation = location ?? (await getLocationData())

    const {
        campaign_id,
        campaign_name,
        country_code,
        client_ip_address,
        ...rest
    } = getAttribution({
        ip: userLocation.ip ?? '',
        country: userLocation?.country ?? '',
    })

    rest.campaignId = getParamByKey('campaign_id')
    rest.campaign = getParamByKey('campaign_name')
    rest.ip = userLocation.ip ?? ''
    rest.country = userLocation.country ?? ''

    const body = {
        userAgent: window.navigator.userAgent,
        event: event,
        ...filterFalsyValues(rest),
        ...filterFalsyValues(options ?? {}),
    }

    return fetcher(`${window.location.origin}/event`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const gerProfileReport = (
    subscriptionId: string,
    idToken: string
): Promise<IQReport> =>
    fetcher(
        `https://cognifi-get-report-55wq3howpa-uc.a.run.app?subscription_id=${subscriptionId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-id-token': idToken,
            },
        }
    )

export const createCertificate = (
    subscription_id: string,
    name: string,
    idToken: string
): Promise<ICertificate> =>
    fetcher(`https://cognifi-create-certificate-55wq3howpa-uc.a.run.app`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-id-token': idToken,
        },
        body: JSON.stringify({ subscription_id, name }),
    })

export const getProfile = (
    subscription_id: string,
    idToken: string
): Promise<IProfile> =>
    fetcher(
        `https://cognifi-get-profile-55wq3howpa-uc.a.run.app?subscription_id=${subscription_id}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-id-token': idToken,
            },
        }
    )

type SubscriptionsResponse = {
    subscriptions: Array<{
        bundle: string
        id: string
        started_at: string
        status: string
        plan_code: string
        main_plan: boolean
    }>
}
export async function getUserSubscriptions(
    uid: string,
    recurlyUri: string
): Promise<SubscriptionsResponse> {
    return fetcher(recurlyUri + '/subscriptions/' + uid, {
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain',
        },
    })
}

export async function saveUserData({
    user_id,
    iq_value,
    subscription_id,
    name,
    email,
    idToken,
}: {
    user_id: string
    iq_value: string
    subscription_id: string
    name: string
    email: string
    idToken: string
}): Promise<any> {
    return fetcher('https://cognifi-create-report-55wq3howpa-uc.a.run.app', {
        method: 'POST',
        body: JSON.stringify({
            user_id,
            iq_value,
            subscription_id,
            name,
            email,
        }),
        headers: {
            'Content-Type': 'application/json',
            'x-id-token': idToken,
        },
    })
}

export interface CancelSubscriptionSuccess {
    success: boolean;
}
export interface CancelSubscriptionError {
    error: string;
}

export const cancelSubscription = async (
    subscriptionId: string
): Promise<CancelSubscriptionSuccess | CancelSubscriptionError> => {

    const domain = process.env.NODE_ENV === 'development' ? 'https://dev.cognifi.one' : window.location.origin;

    return fetcher(`${domain}/cognifi/api/cancel`, {
        method: 'POST',
        body: JSON.stringify({
            id: subscriptionId,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
