import React from 'react'
import styled, { css, keyframes } from 'styled-components'

type Props = {
    className?: string
    absolute?: boolean
    text?: string
    color?: string
}

export default function Spinner({
    className,
    color = 'white',
    absolute = true,
    text,
}: Props) {
    return (
        <Container className={className} absolute={absolute}>
            <Circle color={color} />
            {text ? <StatusText>{text}</StatusText> : null}
        </Container>
    )
}

const spin = keyframes`
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
`

const Circle = styled.div<{ color: string }>`
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border: 4px solid ${(props) => props.color};
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: ${spin} 1s infinite linear;
`

const Container = styled.div<{ absolute?: boolean }>`
    position: relative;
    z-index: 5;

    ${(props) =>
        props.absolute
            ? css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
              `
            : ''};
`

const StatusText = styled.span`
    display: block;
    font-weight: bold;
    margin-top: 10px;
`
