import * as S from './Article.style'
import { ArticleType, CATHEGORIES } from '../Articles.constants'
import BackArrow from '@assets/svg/webapp/articles/back_arrow.svg'
import DividerDecor from '@assets/svg/webapp/articles/divider_decor.svg'
import { useEffect } from 'react'

export default function Article({
    selectedArticle,

    backButtonCathegory,
    onBackClick,
}: {
    selectedArticle: ArticleType & { cathegory: string }
    backButtonCathegory: string
    onBackClick: () => void
}) {
    const selectedCathegoryData = CATHEGORIES.find(
        (tag) => tag.cathegory === backButtonCathegory
    )
    const cathegoryData = CATHEGORIES.find(
        (tag) => tag.cathegory === selectedArticle.cathegory
    )

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <S.ArticleWrapper>
            <S.BackButton onClick={onBackClick}>
                <BackArrow />
                {selectedCathegoryData?.title}
            </S.BackButton>
            {cathegoryData && (
                <S.ArticleTag>
                    {cathegoryData.icon}
                    {cathegoryData.title}
                </S.ArticleTag>
            )}
            <S.ArticleTitle>{selectedArticle.title}</S.ArticleTitle>
            <S.Image>
                <img src={selectedArticle.image_src} alt="article"></img>
            </S.Image>
            <div>
                {selectedArticle.sections.map((section, index) => (
                    <div key={index}>
                        {index !== 0 && (
                            <S.DividerWrapper>
                                <S.DividerLine />
                                <DividerDecor />
                                <S.DividerLine />
                            </S.DividerWrapper>
                        )}
                        <S.ArticleSectionTitle>
                            {section.title}
                        </S.ArticleSectionTitle>
                        <S.ArticleSectionText>
                            {section.content}
                        </S.ArticleSectionText>
                    </div>
                ))}
            </div>
            <S.SourcesWrapper>
                <S.ArticleSectionTitle>Sources</S.ArticleSectionTitle>
                {selectedArticle.sources.map((source, index) => (
                    <S.ArticleSource key={index}>
                        <S.ArticleSourceNumber>
                            {index + 1}
                        </S.ArticleSourceNumber>
                        <div>
                            <S.ArticleSourceTitle>
                                {source.title}
                            </S.ArticleSourceTitle>
                            <S.ArticleSourceLink
                                href={source.url}
                                target="_blank"
                            >
                                {source.url}
                            </S.ArticleSourceLink>
                        </div>
                    </S.ArticleSource>
                ))}
            </S.SourcesWrapper>
        </S.ArticleWrapper>
    )
}
