import { useEffect } from 'react'
import GameHeader from '../components/GameHeader'

export default function FruitMerge({
    setLoading,
    onClose,
}: {
    setLoading: (v: boolean) => void
    onClose: () => void
}) {
    useEffect(() => {
        document.body.style.overflow = 'hidden'

        const handleIframeMessage = (event: MessageEvent) => {
            if (typeof event.data === 'string') {
                try {
                    const decrypted: {
                        type: string
                        hC: {
                            lC: number
                        }
                    } = JSON.parse(atob(event.data))

                    const { type, hC } = decrypted

                    switch (type) {
                        // BTN_STARTGAME_CLICK
                        // BTN_RETRY_CLICK
                        case 'a':
                        case 'e': {
                            break
                        }
                        // GAME_LOADED
                        case 'b': {
                            setLoading(false)
                            break
                        }
                        // GAME_OVER
                        case 'c': {
                            break
                        }
                        // GAME_SCORE_CHANGED
                        case 'd': {
                            //   setIngameScore(hC.lC);
                            break
                        }
                        default: {
                            // console.log('Unknown event:', event);
                            break
                        }
                    }
                } catch (err) {
                    // console.error('err',err);
                }
            }
        }
        window.addEventListener('message', handleIframeMessage)
        return () => {
            window.removeEventListener('message', handleIframeMessage)
            document.body.style.overflow = 'auto'
        }
    }, [setLoading])

    return (
        <div
            style={{
                position: 'fixed',
                zIndex: 50,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: 'white',
            }}
        >
            <GameHeader onClick={onClose} />
            <iframe
                title="Block Puzzle"
                style={{
                    border: 'none',
                    width: '100%',
                    height: '100%',
                    userSelect: 'none',
                }}
                src={'/cognifi/games/fruit_merge/index.html'}
            ></iframe>
        </div>
    )
}
