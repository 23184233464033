import AllArticlesIcon from '@assets/svg/webapp/articles/all_articles.svg'
import LifeHacksIcon from '@assets/svg/webapp/articles/life_hacks.svg'
import BrainHealthIcon from '@assets/svg/webapp/articles/brain_health.svg'
import BrainBasicsIcon from '@assets/svg/webapp/articles/brain_basics.svg'

export const CATHEGORIES = [
    {
        title: 'All articles',
        cathegory: 'all_articles',
        color: '#6639E6',
        icon: <AllArticlesIcon />,
    },
    {
        title: 'Brain Basics',
        cathegory: 'brain-basics',
        color: '#AF5FFF',
        icon: <BrainBasicsIcon />,
    },
    {
        title: 'Life Hacks',
        cathegory: 'life-hacks',
        color: '#ED6EEF',
        icon: <LifeHacksIcon />,
    },
    {
        title: 'Brain Health',
        cathegory: 'brain-health',
        color: '#FF84D5',
        icon: <BrainHealthIcon />,
    },
]

type ArticleSection = {
    title: string
    content: string
}

type ArticleSource = {
    title: string
    url: string
}

export type ArticleType = {
    image_src: string
    title: string
    sections: Array<ArticleSection>
    sources: Array<ArticleSource>
}

export type ArticlesResponseType = {
    'brain-basics': Array<ArticleType>
    'life-hacks': Array<ArticleType>
    'brain-health': Array<ArticleType>
}
