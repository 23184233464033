import styled from 'styled-components'

import { PageTitle, PageWrapper } from '@components/WebApp/pages/common.style'

export const GroupPoint = styled.div``

export const StyledPageWrapper = styled(PageWrapper)`
    color: var(--black-color);
`

export const StyledPageTitle = styled(PageTitle)`
    line-height: 32px;
`

export const IqValue = styled.span`
    font-family: Avenir700;
    font-size: 56px;
`

export const Title = styled.h2`
    margin: 0;
    font-family: Avenir600;
    font-size: 24px;
    text-align: center;
    line-height: 32px;
`

export const Description = styled.p`
    margin: 0;
    font-family: Avenir400;
    font-size: 16px;
    line-height: 22px;

    &.black-50-color {
        color: var(--black-50-color);
    }
`

export const GroupItem = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const IqScore = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Point = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px -20px 0;

    &:after,
    &:before {
        position: absolute;
        content: '';
        top: 50%;
        display: block;
        width: calc(50% - 100px);
        height: 1px;
        background: var(--grey-color);
        z-index: 1;
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }
`

export const PointValue = styled.span`
    font-family: Avenir700;
    font-size: 40px;
    color: var(--primary-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`

export const SubGroup = styled.div`
    margin-top: 30px;
`

export const SubTitle = styled.span`
    font-family: Avenir600;
    font-size: 20px;
    line-height: 28px;
`

export const SubItems = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const SubItemCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 32px;
    min-width: 32px;
    min-height: 44px;
`

export const SubItem = styled.div`
    display: flex;
    align-items: stretch;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;

    &.strengths {
        ${SubItemCol} {
            background: var(--green-color);
        }

        background: var(--green-10-color);

        strong {
            color: var(--green-color);
        }
    }

    &.applications {
        ${SubItemCol} {
            background: var(--primary-color);
        }

        background: var(--primary-10-color);

        strong {
            color: var(--primary-color);
        }
    }

    &.recommendations_for_development {
        ${SubItemCol} {
            background: var(--pink-color);
        }

        background: var(--pink-10-color);

        strong {
            color: var(--pink-color);
        }
    }
`

export const SubItemText = styled.span`
    font-family: Avenir400;
    font-size: 14px;
    line-height: 22px;
    align-self: center;

    strong {
        font-family: Avenir600;
        font-size: 16px;
    }
`

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 40px -20px -40px;
    padding: 40px 20px;
    background: var(--grey-light-color);
`

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const FooterText = styled.span`
    display: inline-block;
    margin-top: 30px;
    font-family: Avenir600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px;
`
