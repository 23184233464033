import { Box, Link, Typography } from '@mui/material';
import { Routes } from '../../App';

function CommonFooterItems() {
  return (
    <>
      <Typography
        sx={{
          fontFamily: 'Avenir400',
          fontSize: '12px',
          lineHeight: 'normal',
          textAlign: 'center',
          color: '#908C89',
          userSelect: 'none',
          marginTop: '20px',
        }}
      >
        All rights reserved. 2024
      </Typography>
      <Box
        sx={{
          height: '1px',
          margin: '10px 0',
          background: '#E6E7EB',
          width: '100%',
        }}
      ></Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-around',
          '& .link': {
            userSelect: 'none',
            fontFamily: 'Avenir400',
            fontSize: '12px',
            color: 'rgba(2, 2, 2, 0.50)',
            textDecoration: 'none',
            transition: 'all 0.3s ease',
            whiteSpace: 'nowrap',
            '&:hover': {
              opacity: 0.8,
            },
          },
        }}
      >
        <Link target="_blank" href={Routes.PRIVACY} className="link">
          Privacy Policy
        </Link>
        <Link target="_blank" href={Routes.TERMS} className="link">
          Terms of use
        </Link>
        <Link target="_blank" href={Routes.COOKIE_POLICY} className="link">
          Cookie Policy
        </Link>
      </Box>
    </>
  );
}

export default CommonFooterItems;
