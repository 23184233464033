import { useEffect, useState } from 'react';
import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import QuizContainer from '../../common/QuizContainer';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Lottie from 'react-lottie-player';
import * as animation from '../../../assets/json/JSON_Cognifi-Logo-Loop.json';
import 'swiper/css';

const reviews = [
  {
    name: 'Lisa',
    text: "Cognifi challenges me in the best way possible. It feels like a workout for my brain, and I've noticed improvements in my problem-solving skills.",
    image: '/cognifi/images/person_1.webp',
  },
  {
    name: 'Jacob',
    text: 'After using Cognifi for a while, I’ve seen a noticeable boost in my cognitive abilities. My focus and quick thinking have never been better!',
    image: '/cognifi/images/person_2.webp',
  },
  {
    name: 'Robert Fox',
    text: 'Cognifi is a game-changer! My focus, memory, and overall mental clarity have improved drastically in just a few weeks of using it.',
    image: '/cognifi/images/person_3.webp',
  },
];

function Analysis() {
  const { setStep, quizData, setUserIqScore } = useQuizState();
  const [memory, setMemory] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [concentration, setConcentration] = useState(0);
  const [logic, setLogic] = useState(0);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter < 400) {
      setTimeout(() => {
        setCounter((prev) => prev + 1);
      }, 30);
    }
    if (counter < 100) {
      setMemory((prev) => prev + 1);
    }
    if (counter > 99 && counter < 200) {
      setSpeed((prev) => prev + 1);
    }
    if (counter > 199 && counter < 300) {
      setConcentration((prev) => prev + 1);
    }
    if (counter > 299 && counter < 400) {
      setLogic((prev) => prev + 1);
    }

    if (counter === 400) {
      setTimeout(() => {
        setStep((prev) => prev + 1);
        const correctAnswers = Object.values(quizData.quizAnswers).filter((el) => el?.includes('correct'));
        setUserIqScore(String(90 + correctAnswers.length * 2))
      }, 500);
    }
  }, [counter]);

  return (
    <QuizStepBackgroundWrapper>
      <QuizContainer padding="0">
        <Box
          sx={{
            padding: '0 20px',
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Lottie
            play
            animationData={animation}
            style={{
              width: '70px',
              height: '52px',
            }}
          />
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontWeight: 600,
              fontSize: '24px',
              userSelect: 'none',
              textAlign: 'center',
              color: '#020202',
              lineHeight: 'normal',
              marginTop: '20px',
              marginBottom: '10px',
              span: {
                color: 'var(--primary-color)',
              },
            }}
          >
            Calculating your <span>IQ score...</span>
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Avenir400',
              fontSize: '16px',
              userSelect: 'none',
              textAlign: 'center',
              color: 'rgba(2, 2, 2, 0.50)',
              lineHeight: 'normal',
              marginBottom: '20px',
            }}
          >
            Hang tight as our AI analyzes your answers across the 4 key measures of intelligence for a detailed
            assessment...
          </Typography>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              '& .textWrapper': {
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '5px',
                '& p': {
                  fontFamily: 'Avenir600',
                  fontWeight: 600,
                  fontSize: '14px',
                  userSelect: 'none',
                  color: '#000',
                },
              },
            }}
          >
            <Box>
              <Box className="textWrapper">
                <Typography>Memory</Typography>
                <Typography>{memory}%</Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                sx={{
                  height: '12px',
                  borderRadius: '7px',
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: 'rgba(2, 2, 2, 0.10)',
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: '7px',
                    transition: 'none',
                    backgroundColor: '#6639E6',
                  },
                }}
                value={memory}
              />
            </Box>
            <Box>
              <Box className="textWrapper">
                <Typography>Speed</Typography>
                <Typography>{speed}%</Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                sx={{
                  height: '12px',
                  borderRadius: '7px',
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: 'rgba(2, 2, 2, 0.10)',
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    transition: 'none',
                    borderRadius: '7px',
                    backgroundColor: '#AF5FFF',
                  },
                }}
                value={speed}
              />
            </Box>

            <Box>
              <Box className="textWrapper">
                <Typography>Concentration</Typography>
                <Typography>{concentration}%</Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                sx={{
                  height: '12px',
                  borderRadius: '7px',
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: 'rgba(2, 2, 2, 0.10)',
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    transition: 'none',
                    borderRadius: '7px',
                    backgroundColor: '#ED6EEF',
                  },
                }}
                value={concentration}
              />
            </Box>

            <Box>
              <Box className="textWrapper">
                <Typography>Logic</Typography>
                <Typography>{logic}%</Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                sx={{
                  height: '12px',
                  borderRadius: '7px',
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: 'rgba(2, 2, 2, 0.10)',
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    transition: 'none',
                    borderRadius: '7px',
                    backgroundColor: '#FF84D5',
                  },
                }}
                value={logic}
              />
            </Box>
          </Box>
          <Box
            sx={{
              margin: '20px 0',
              display: 'flex',
              gap: '10px',
            }}
          >
            <img src={memory === 100 ? '/cognifi/svg/analysis_checkmark.svg' : '/cognifi/svg/analysis_unchecked.svg'} alt="checked"></img>
            <img src={speed === 100 ? '/cognifi/svg/analysis_checkmark.svg' : '/cognifi/svg/analysis_unchecked.svg'} alt="checked"></img>
            <img
              src={concentration === 100 ? '/cognifi/svg/analysis_checkmark.svg' : '/cognifi/svg/analysis_unchecked.svg'}
              alt="checked"
            ></img>
            <img src={logic === 100 ? '/cognifi/svg/analysis_checkmark.svg' : '/cognifi/svg/analysis_unchecked.svg'} alt="checked"></img>
          </Box>
        </Box>
        <Box
          sx={{
            paddingBottom: '30px',
            width: '100%',
          }}
        >
          <Swiper
            modules={[Autoplay]}
            style={{
              width: '100%',
              paddingLeft: '10px',
            }}
            slidesPerView={'auto'}
            speed={600}
            loop={true}
            autoplay={true}
          >
            {reviews.map((review, index) => {
              return (
                <SwiperSlide
                  key={review.name + index}
                  style={{
                    maxWidth: 'calc(100% - 40px)',
                    margin: '0 10px',
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      minWidth: '100%',
                      padding: '20px',
                      borderRadius: '20px',
                      border: '1px solid rgba(2, 2, 2, 0.10)',
                      boxShadow: '0px 0px 8px 0px rgba(223, 210, 201, 0.20)',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                          width: '100%',
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Avenir600',
                            fontSize: '16px',
                            userSelect: 'none',
                            lineHeight: 'normal',
                            color: '#020202',
                          }}
                        >
                          {review.name}
                        </Typography>
                        <img width={106} src="/cognifi/svg/review_stars.svg" alt="stars"></img>
                      </Box>

                      <img width={52} src={review.image} alt="person"></img>
                    </Box>

                    <Typography
                      sx={{
                        fontFamily: 'Avenir400',
                        fontSize: '14px',
                        userSelect: 'none',
                        color: '#020202',
                        lineHeight: 'normal',
                        marginTop: '20px',
                      }}
                    >
                      {review.text}
                    </Typography>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Analysis;
