import {
    PageWrapper,
    PageTitle,
    BasePage,
} from '@components/WebApp/pages/common.style'
import * as S from './Compare.style'
import ArrowIcon from '@assets/svg/webapp/compare/compare_arrow.svg'
import UserCardTop from '@assets/svg/webapp/compare/usercard_top.svg'
import UserCardTag from '@assets/svg/webapp/compare/usercard_tag.svg'
import { useEffect, useMemo } from 'react'
import { useUser } from '@providers/UserProvider'
import { Box } from '@mui/material'

export default function Compare() {
    const { profile } = useUser()

    const cardsWithUser = useMemo(() => {
        let res: Array<
            { img: string; iq: number } | { userCard: boolean; iq: number }
        > = [
            {
                img: '/cognifi/images/webapp/compare/1.webp',
                iq: 78,
            },
            {
                img: '/cognifi/images/webapp/compare/2.webp',
                iq: 99,
            },
            {
                img: '/cognifi/images/webapp/compare/3.webp',
                iq: 114,
            },
            {
                img: '/cognifi/images/webapp/compare/4.webp',
                iq: 116,
            },
            {
                img: '/cognifi/images/webapp/compare/5.webp',
                iq: 119,
            },
            {
                img: '/cognifi/images/webapp/compare/6.webp',
                iq: 120,
            },

            {
                img: '/cognifi/images/webapp/compare/7.webp',
                iq: 124,
            },
            {
                img: '/cognifi/images/webapp/compare/8.webp',
                iq: 130,
            },
            {
                img: '/cognifi/images/webapp/compare/9.webp',
                iq: 136,
            },
            {
                img: '/cognifi/images/webapp/compare/10.webp',
                iq: 140,
            },
            {
                img: '/cognifi/images/webapp/compare/11.webp',
                iq: 140,
            },
            {
                img: '/cognifi/images/webapp/compare/12.webp',
                iq: 152,
            },
            {
                img: '/cognifi/images/webapp/compare/13.webp',
                iq: 160,
            },
            {
                img: '/cognifi/images/webapp/compare/14.webp',
                iq: 160,
            },
        ]

        const iqScore = profile?.iq_value ?? 115
        res.push({ userCard: true, iq: Number(iqScore) })
        return res.sort((a, b) => a.iq - b.iq)
    }, [profile])

    useEffect(() => {
        const user_card = document.getElementById('user_card')
        if (user_card) {
            user_card.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
                block: 'end',
            })
        }
    })

    return (
        <BasePage>
            <Box
                sx={{
                    overflow: 'hidden',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                }}
            >
                <PageTitle className="center">
                    <b>Find out</b> how you measure up against others
                </PageTitle>
                <S.SwipeBlock>
                    <ArrowIcon />
                    <S.Text>Swipe to compare your IQ with celebrities</S.Text>
                    <S.RightArrowIcon></S.RightArrowIcon>
                </S.SwipeBlock>
                <S.CompareWrapper>
                    <S.CompareInner>
                        {cardsWithUser.map((card, index) => {
                            return 'img' in card ? (
                                <S.CompareImage
                                    key={index}
                                    src={card.img}
                                    alt="card"
                                />
                            ) : (
                                <S.UserCard
                                    id="user_card"
                                    $height={155 + card.iq}
                                    key={index}
                                >
                                    <S.UserCardStat>
                                        IQ {card.iq}
                                    </S.UserCardStat>
                                    <UserCardTop />
                                    <S.CardTagWrapper>
                                        <UserCardTag />
                                    </S.CardTagWrapper>
                                </S.UserCard>
                            )
                        })}
                    </S.CompareInner>
                </S.CompareWrapper>
            </Box>
        </BasePage>
    )
}
