import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getPathWithSearchParams } from '@utils/ulr'
import { Routes } from '../../App'
import QuizContainer from '@components/common/QuizContainer'
import { isIos, isMetaInAppBrowser } from '@utils/browser'
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks'
import QuizButton, { ButtonType } from '@components/common/QuizButton'
import { useEffect, useState } from 'react'
import {
    firebaseAuth,
    signInWithApple,
    signInWithGoogle,
} from '@services/firebase'
import { useUser } from '@providers/UserProvider'
import { getUserSubscriptions } from '@api/requests'
import { usePaymentConfig } from '@providers/PaymentConfigProvider'
import { signOut } from 'firebase/auth'
import { getRedirectToTestUrl, isOnAppDomain } from '@utils/payment'

function SignIn() {
    const navigate = useNavigate()
    const showAppleSignIButton = isIos()
    const { loading } = usePaymentState()
    const [error, setError] = useState<string | null>(null)
    const [isModalOpen, setModalOpen] = useState(false)
    const { user, setUser } = useUser()
    const { paymentConfig } = usePaymentConfig()

    useEffect(() => {
        if (user) {
            const recurlyUri =
                paymentConfig?.recurly?.uri ??
                process.env.REACT_APP_RECURLY_URI ??
                ''

            const isAppleAuth = user.isAppleAuth
            const userId = isAppleAuth
                ? (user.providerDataId ?? user.uid)
                : user.uid

            getUserSubscriptions(userId, recurlyUri)
                .then(async (response) => {
                    const activeSubscription = response.subscriptions.find(
                        (subscription) => {
                            return (
                                subscription.bundle === 'cognifi' &&
                                subscription.main_plan &&
                                ['active', 'canceled'].includes(
                                    subscription.status
                                )
                            )
                        }
                    )

                    if (activeSubscription) {
                        navigate(getPathWithSearchParams(Routes.WEBAPP_TESTS))
                    } else {
                        setModalOpen(true)
                        // remove user form app state to prevent create subscription activate/finalize
                        signOut(firebaseAuth)
                        setUser(null)
                    }
                })
                .catch((error) => {
                    setError('Something went wrong')
                    console.error('getUserSubscriptions error', error)
                })
        }
    }, [user, navigate, paymentConfig, setUser])

    const handleStartTestClick = () => {
        if (isOnAppDomain) {
            window.open(getRedirectToTestUrl(), '_self')
        } else {
            navigate(getPathWithSearchParams(Routes.QUIZ))
        }
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                zIndex: 199,
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                width: '100%',
                background: '#fff',
                backgroundImage:
                    'url(images/gradient_background_fullscreen.webp)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <QuizContainer>
                <Box
                    sx={{
                        marginTop: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <img
                        width={'70px'}
                        height={'52px'}
                        src={'svg/intro_sign.svg'}
                        alt="logo"
                    ></img>
                    <Typography
                        sx={{
                            fontFamily: 'Avenir600',
                            fontWeight: 600,
                            fontSize: '24px',
                            userSelect: 'none',
                            lineHeight: 'normal',
                            textAlign: 'center',
                            color: '#020202',
                            marginTop: '30px',
                            marginBottom: '10px',
                            span: {
                                color: 'var(--primary-color)',
                            },
                        }}
                    >
                        Welcome to <span>Cognifi</span>
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Avenir400',
                            fontSize: '16px',
                            userSelect: 'none',
                            textAlign: 'center',
                            lineHeight: 'normal',
                            color: 'rgba(2, 2, 2, 0.50)',
                        }}
                    >
                        Access a top-tier brain training solution designed for
                        significant, long-term cognitive improvement.
                    </Typography>

                    <Box
                        sx={{
                            marginTop: '20px',
                            display: 'flex',
                            width: '100%',
                            padding: '20px',
                            background: '#fff',
                            gap: '10px',
                            flexDirection: 'column',
                            borderRadius: '24px',
                            boxShadow:
                                '0px 5px 20px 0px rgba(69, 50, 120, 0.05)',
                            '& button.sign-in-button': {
                                fontFamily: 'Avenir600',
                                fontSize: '16px',
                                lineHeight: '22px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                borderRadius: '12px',
                                textTransform: 'none',
                                padding: '14px',
                                width: '100%',
                            },
                        }}
                    >
                        {showAppleSignIButton && (
                            <>
                                <Button
                                    disabled={loading}
                                    onClick={signInWithApple}
                                    className="sign-in-button"
                                    sx={{
                                        background: '#020202',
                                        color: 'white',
                                        '&:hover': {
                                            background: '#020202',
                                        },
                                    }}
                                >
                                    <img
                                        src={'svg/apple-logo.svg'}
                                        alt="apple"
                                    />{' '}
                                    Sign in with Apple
                                </Button>
                                {!isMetaInAppBrowser && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '20px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                width: '100%',
                                                height: '1px',
                                                background: '#E6E7EB',
                                            }}
                                        />{' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Avenir400',
                                                fontSize: '16px',
                                                lineHeight: 'normal',
                                                color: 'rgba(2, 2, 2, 0.50)',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            or
                                        </Typography>
                                        <Typography
                                            sx={{
                                                width: '100%',
                                                height: '1px',
                                                background: '#E6E7EB',
                                            }}
                                        />
                                    </Box>
                                )}
                            </>
                        )}

                        {!isMetaInAppBrowser && (
                            <Button
                                disabled={loading}
                                onClick={signInWithGoogle}
                                className="sign-in-button"
                                sx={{
                                    background: 'white',
                                    color: '#020202',
                                    border: '1px solid rgba(2, 2, 2, 0.10)',
                                    '&:hover': {
                                        background: 'white',
                                    },
                                }}
                            >
                                <img src={'svg/google-logo.svg'} alt="google" />
                                Sign in with Google
                            </Button>
                        )}
                    </Box>

                    {error && (
                        <Typography
                            sx={{ margin: '20px 0 0 0', color: '#E03045' }}
                        >
                            {error}
                        </Typography>
                    )}
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Box
                        sx={{
                            borderTop: '1px solid #E6E7EB',
                            padding: '20px 0',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '5px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Avenir400',
                                fontSize: '14px',
                                color: 'rgba(2, 2, 2, 0.50)',
                                textAlign: 'center',
                                userSelect: 'none',
                                lineHeight: 'normal',
                            }}
                        >
                            Haven't created an account yet?
                        </Typography>
                        <Button
                            onClick={handleStartTestClick}
                            sx={{
                                backgroundColor: 'var(--primary-color)',
                                color: '#fff',
                                fontFamily: 'Avenir600',
                                fontSize: '14px',
                                borderRadius: '25px',
                                padding: '10px 20px',
                                textWrap: 'nowrap',
                                textTransform: 'initial',
                                width: 'max-content',
                                '&:hover': {
                                    backgroundColor: 'var(--primary-color)',
                                },
                            }}
                        >
                            Start Certified Test
                        </Button>
                    </Box>
                </Box>
            </QuizContainer>
            {isModalOpen && (
                <Box
                    sx={{
                        position: 'fixed',
                        zIndex: 299,
                        bottom: 0,
                        top: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        userSelect: 'none',
                        background: 'rgba(2, 2, 2, 0.50)',
                    }}
                >
                    <Box
                        sx={{
                            marginTop: 'auto',
                            maxWidth: '460px',
                            background: '#FFF',
                            width: '100%',
                            padding: '30px 20px 0',
                            borderRadius: '24px 24px 0 0',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Avenir600',
                                fontSize: '24px',
                                lineHeight: 'normal',
                                color: '#020202',
                                textAlign: 'center',
                                userSelect: 'none',
                            }}
                        >
                            Unfortunately, such an account does not exist.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Avenir400',
                                fontSize: '16px',
                                lineHeight: 'normal',
                                color: 'rgba(2, 2, 2, 0.50)',
                                textAlign: 'center',
                                userSelect: 'none',
                                marginTop: '5px',
                                marginBottom: '20px',
                            }}
                        >
                            We couldn't find you in our user list. If you're new
                            to Cognifi, please get pass a short quiz to access
                            exclusive content.
                        </Typography>
                        <QuizButton
                            onClick={handleStartTestClick}
                            variant={ButtonType.MAIN}
                            text={'Start a test'}
                        />

                        <Typography
                            onClick={() => {
                                setModalOpen(false)
                            }}
                            sx={{
                                fontFamily: 'Avenir400',
                                fontSize: '16px',
                                lineHeight: 'normal',
                                color: 'rgba(2, 2, 2, 0.50)',
                                textAlign: 'center',
                                userSelect: 'none',
                                marginTop: '20px',
                                marginBottom: '20px',
                            }}
                        >
                            Not now
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default SignIn
