import { useState, useEffect } from 'react'

const useScrollPosition = (scrollThreshold: number) => {
    const [hasScrolled, setHasScrolled] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY
            if (scrollY > scrollThreshold) {
                setHasScrolled(true)
            } else {
                setHasScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [scrollThreshold])

    return hasScrolled
}

export default useScrollPosition
