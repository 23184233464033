import React from 'react';
import { Box, Typography } from '@mui/material';
import ChoosePlan from './ChoosePlan';
import { latestResults } from '@components/Main/Main';
import Lottie from 'react-lottie-player';
import * as animation from '../../../assets/json/JSON_Cognifi-Logo-Loop.json';

const Checkmark = (color: string) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9718 11.0004C21.7123 11.0082 21.4661 11.1167 21.2852 11.3029L14.0063 18.588L11.7192 16.2983C11.4687 16.0373 11.0968 15.9321 10.7468 16.0234C10.3969 16.1147 10.1236 16.3882 10.0323 16.7383C9.94109 17.0885 10.0462 17.4607 10.3071 17.7113L13.3002 20.7074C13.6902 21.0975 14.3224 21.0975 14.7124 20.7074L22.6974 12.7159C22.9927 12.4286 23.0815 11.9894 22.921 11.6099C22.7605 11.2303 22.3835 10.9883 21.9718 11.0004Z"
        fill="white"
      />
    </svg>
  );
};
function MainContent() {
  const reviews = [
    {
      name: 'Lisa',
      text: "Cognifi challenges me in the best way possible. It feels like a workout for my brain, and I've noticed improvements in my problem-solving skills.",
      image: 'images/person_1.webp',
    },
    {
      name: 'Jacob',
      text: 'After using Cognifi for a while, I’ve seen a noticeable boost in my cognitive abilities. My focus and quick thinking have never been better!',
      image: 'images/person_2.webp',
    },
    {
      name: 'Robert Fox',
      text: 'Cognifi is a game-changer! My focus, memory, and overall mental clarity have improved drastically in just a few weeks of using it.',
      image: 'images/person_3.webp',
    },
  ];
  const highlights = [
    {
      text: (
        <>
          <span>Tackle</span> complex problems with improved clarity and confidence
        </>
      ),
      color: '#6639E6',
    },
    {
      text: (
        <>
          <span>Surpass</span> your peers and thrive in competitive settings
        </>
      ),
      color: '#9540E1',
    },
    {
      text: (
        <>
          <span>Acquire</span> new skills more quickly and retain information more efficiently
        </>
      ),
      color: '#B84BDD',
    },
    {
      text: (
        <>
          <span>Unlock</span> new career prospects and reach your professional objectives
        </>
      ),
      color: '#D55BD9',
    },
    {
      text: (
        <>
          <span>Make more informed</span> decisions in every area of your life
        </>
      ),
      color: '#EC6ED6',
    },
    {
      text: (
        <>
          <span>Enhance</span> your confidence and self-assurance to face new challenges
        </>
      ),
      color: '#FF84D5',
    },
  ];

  const why = [
    {
      title: 'Validated IQ Test',
      subtitle:
        'Our assessment is based on the Stanford-Binet Intelligence Scale, which has been the benchmark for IQ testing since 1916.',
      image: 'images/subs_image_1.webp',
    },
    {
      title: 'Detailed report',
      subtitle:
        'Your customized report is created using the well-regarded Cattell-Horn-Carrol (CHC) theory of cognitive abilities.',
      image: 'images/subs_image_2.webp',
    },
    {
      title: 'Science-based training',
      subtitle:
        'Our cognitive training programs are based on cutting-edge neuroscience research, which has demonstrated their effectiveness in enhancing cognitive function and raising IQ scores.',
      image: 'images/subs_image_3.webp',
    },
  ];
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '460px',
          margin: 'auto',
          padding: '0 20px',
        }}
      >
        <ChoosePlan />
      </Box>

      <Box
        sx={{
          maxWidth: '460px',
          background: '#F3F2F8',
          padding: '40px 20px',
          marginTop: '40px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <Lottie
            play
            animationData={animation}
            style={{
              width: '105px',
              height: '78px',
            }}
          />
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '24px',
              lineHeight: 'normal',
              color: '#000',
              userSelect: 'none',
              textAlign: 'center',
              span: {
                color: '#6639E6',
              },
            }}
          >
            Why you can trust <span>Cognifi</span>
          </Typography>

          {why.map((el) => (
            <Box
              key={el.title}
              sx={{
                background: '#FFF',
                borderRadius: '20px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                filter: 'drop-shadow(0px 5px 20px rgba(69, 50, 120, 0.05))',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir600',
                  fontSize: '20px',
                  lineHeight: 'normal',
                  color: '#020202',
                  userSelect: 'none',
                  margin: '30px 20px 10px',
                }}
              >
                {el.title}
              </Typography>

              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  fontSize: '16px',
                  lineHeight: 'normal',
                  color: '#020202',
                  userSelect: 'none',
                  margin: '0 20px 10px',
                }}
              >
                {el.subtitle}
              </Typography>
              <img width={'100%'} src={el.image} alt={el.title}></img>
            </Box>
          ))}
        </Box>
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '24px',
            lineHeight: 'normal',
            color: '#000',
            userSelect: 'none',
            marginTop: '40px',
            marginBottom: '20px',
            textAlign: 'center',
            span: {
              color: '#6639E6',
            },
          }}
        >
          How <span>improving your IQ</span> score can impact your life
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          {highlights.map((el, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                gap: '15px',
                alignItems: 'center',
                svg: {
                  minWidth: '32px',
                },
              }}
            >
              {Checkmark(el.color)}
              <Box>
                <Typography
                  sx={{
                    fontFamily: 'Avenir400',
                    fontSize: '16px',
                    lineHeight: 'normal',
                    color: '#020202',
                    userSelect: 'none',
                    span: {
                      fontFamily: 'Avenir600',
                      color: el.color,
                    },
                  }}
                >
                  {el.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '460px',
          margin: 'auto',
          padding: '0 20px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '24px',
            lineHeight: 'normal',
            userSelect: 'none',
            color: '#000',
            textAlign: 'center',
            marginTop: '40px',
            marginBottom: '20px',
            span: {
              color: '#6639E6',
            },
          }}
        >
          <span>Latest</span> results of our users who passed the IQ test
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            '& .wrapper': {
              padding: '4px 4px 4px 20px',
              borderRadius: '20px',
              background: '#F3F2F8',
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          {latestResults.map((result) => (
            <Box key={result.title} className="wrapper">
              <img width={48} src={result.flag} alt={result.title}></img>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginLeft: '10px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '16px',
                    color: '#020202',
                    userSelect: 'none',
                    lineHeight: 'normal',
                  }}
                >
                  {result.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Avenir400',
                    fontSize: '14px',
                    color: '#020202',
                    userSelect: 'none',
                    lineHeight: 'normal',
                  }}
                >
                  {result.subTitle}
                </Typography>
              </Box>

              <img width={100} src={result.image} alt={result.title}></img>
            </Box>
          ))}
        </Box>

        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '24px',
            lineHeight: 'normal',
            userSelect: 'none',
            color: '#000',
            textAlign: 'center',
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          Feedback from our users
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          {reviews.map((review, index) => (
            <Box
              key={index}
              sx={{
                border: '1px solid #E6E7EB',
                background: 'white',
                borderRadius: '16px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: ' 20px',
                boxShadow: '0px 0px 8px 0px rgba(216, 216, 216, 0.30)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    userSelect: 'none',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontSize: '16px',
                      lineHeight: 'normal',
                      color: '#000',
                      userSelect: 'none',
                      marginBottom: '5px',
                    }}
                  >
                    {review.name}
                  </Typography>
                  <img draggable={false} src={'svg/review_stars.svg'} alt="stars"></img>
                </Box>
                <Box
                  sx={{
                    borderRadius: '50%',
                    overflow: 'hidden',
                    width: '52px',
                    height: '52px',
                    backgroundSize: 'contain',
                    backgroundImage: `url(${review.image})`,
                  }}
                ></Box>
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  fontSize: '14px',
                  lineHeight: 'normal',
                  userSelect: 'none',
                  color: '#000',
                }}
              >
                {review.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default MainContent;
