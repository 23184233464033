import {
    PageWrapper,
    PageTitle,
    BasePage,
} from '@components/WebApp/pages/common.style'
import * as S from './Articles.style'
import { useEffect, useMemo, useState } from 'react'
import ArticleCard from './components/ArticleCard'
import {
    ArticlesResponseType,
    ArticleType,
    CATHEGORIES,
} from './Articles.constants'
import Article from './components/Article'

export default function Articles() {
    const [articlesData, setArticlesData] =
        useState<ArticlesResponseType | null>(null)
    const [selectedCathegory, setSelectedCathegory] = useState(
        CATHEGORIES[0].cathegory
    )

    const [selectedArticle, setSelectedArticle] = useState<
        (ArticleType & { cathegory: string }) | null
    >(null)

    const fetchArticles = async () => {
        const articles: ArticlesResponseType = await (
            await fetch('/cognifi/articles.json')
        ).json()
        setArticlesData(articles)
    }

    useEffect(() => {
        fetchArticles()
    }, [])

    const articlesToShow:
        | Array<ArticleType & { cathegory: string }>
        | undefined = useMemo(() => {
        if (!articlesData) return

        if (articlesData[selectedCathegory as keyof typeof articlesData]) {
            return articlesData[
                selectedCathegory as keyof typeof articlesData
            ].map((el) => ({ ...el, cathegory: selectedCathegory }))
        } else {
            return [
                ...articlesData['brain-basics'].map((el) => ({
                    ...el,
                    cathegory: 'brain-basics',
                })),
                ...articlesData['brain-health'].map((el) => ({
                    ...el,
                    cathegory: 'brain-health',
                })),
                ...articlesData['life-hacks'].map((el) => ({
                    ...el,
                    cathegory: 'life-hacks',
                })),
            ].sort(() => Math.random() - 0.5)
        }
    }, [selectedCathegory, articlesData])

    return selectedArticle ? (
        <PageWrapper>
            <Article
                selectedArticle={selectedArticle}
                backButtonCathegory={selectedCathegory}
                onBackClick={() => {
                    setSelectedArticle(null)
                }}
            />
        </PageWrapper>
    ) : (
        <BasePage>
            <PageWrapper>
                <PageTitle>Articles</PageTitle>
                <S.TagsWrapper>
                    {CATHEGORIES.map((tag) => (
                        <S.Tag
                            key={tag.title}
                            $color={tag.color}
                            $isSelected={selectedCathegory === tag.cathegory}
                            onClick={() => {
                                setSelectedCathegory(tag.cathegory)
                            }}
                        >
                            {tag.icon}
                            {tag.title}
                        </S.Tag>
                    ))}
                </S.TagsWrapper>

                <S.ArticlesWrapper>
                    {articlesToShow?.map((article, index) => (
                        <ArticleCard
                            key={index}
                            article={article}
                            onClick={() => {
                                setSelectedArticle(article)
                            }}
                        ></ArticleCard>
                    ))}
                </S.ArticlesWrapper>
            </PageWrapper>
        </BasePage>
    )
}
