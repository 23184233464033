const planTexts = {
  offer: {
    totalTitle: 'Total Today',
    specialOfferTitle: 'SPECIAL OFFER',
  },
  plan: {
    trial: {
      day: {
        '0': '{{}}-Day Trial',
        '1': '{{}}-Day Trial',
        '2': '{{}}-Day Trial',
      },
      month: {
        '0': '{{}}-Month Trial',
        '1': '{{}}-Month Trial',
        '2': '{{}}-Month Trial',
      },
      year: {
        '0': '{{}}-Year Trial',
        '1': '{{}}-Year Trial',
        '2': '{{}}-Year Trial',
      },
    },
    base: {
      day: {
        '0': '{{}}-Day plan',
        '1': '{{}}-Day plan',
        '2': '{{}}-Day plan',
      },
      month: {
        '0': '{{}}-Month plan',
        '1': '{{}}-Month plan',
        '2': '{{}}-Month plan',
      },
      year: {
        '0': '{{}}-Year plan',
        '1': '{{}}-Year plan',
        '2': '{{}}-Year plan',
      },
    },
  },
  duration: {
    day: {
      '0': 'day',
      '1': 'day',
      '2': 'days',
    },
    month: {
      '0': 'month',
      '1': 'month',
      '2': 'months',
    },
    year: {
      '0': 'year',
      '1': 'year',
      '2': 'years',
    },
  },
  planDescription: {
    '0': '*Your {{planName}} will last until ({{subscriptionDate}}). You can cancel anytime before then and will not be charged the full program amount.',
    '1': 'No question asked, no small print. If you decide {{appName}} is good for you, on ({{subscriptionDate}}) you will be charged one payment for {{sum}} for your {{duration}} course ({{priceForPart}}).',
    '2': '{{appName}} will automatically charge your card {{sum}} every {{duration}} so you don’t lose access to your account. No refunds or credits except if you don’t see results and follow our money-back policy. To cancel, simply let us know by email:',
  },
};

function getValidWord(value: number, words: Array<any>) {
  value = Math.abs(value) % 100;

  const num = value % 10;

  if (value > 10 && value < 20) return words[2];

  if (num > 1 && num < 5) return words[1];

  if (num === 1) return words[0];

  return words[2];
}

class PlansUtils {
  getPlaneName(length: string, isTrialPlan: boolean) {
    if (!length) return '';

    const durationValue = Number(length.match(/-?\d+(\.\d+)?/g)?.[0]);

    if (!durationValue) return '';

    let prefixPlan: 'trial' | 'base' = 'base';

    if (isTrialPlan) {
      prefixPlan = 'trial';
    }

    function getFinalPlanName(
      key: 'day' | 'month' | 'year',
      prefix: 'base' | 'trial'
    ) {
      return getValidWord(durationValue, [
        planTexts.plan[prefix][key]['0'],
        planTexts.plan[prefix][key]['1'],
        planTexts.plan[prefix][key]['2'],
      ]);
    }

    if (/d/gi.test(length)) {
      return getFinalPlanName('day', prefixPlan).replace('{{}}', durationValue);
    }

    if (/m/gi.test(length)) {
      return getFinalPlanName('month', prefixPlan).replace(
        '{{}}',
        durationValue
      );
    }

    if (/y/gi.test(length)) {
      return getFinalPlanName('year', prefixPlan).replace(
        '{{}}',
        durationValue
      );
    }

    return '';
  }

  getPriceWithDiscount(price: number, discountPercent: number) {
    return Number((price * 100) / (100 - discountPercent)).toFixed(2);
  }

  getCurrentPricePerDayForPlan(
    planName: string,
    price: number,
    isShortPlanName?: boolean
  ) {
    const period = Number(planName.match(/-?\d+(\.\d+)?/g)?.[0]);

    if (!period || !price) return '';

    let result = 0;
    const Month = isShortPlanName ? 'm' : 'month';
    const Day = isShortPlanName ? 'd' : 'day';
    const Year = isShortPlanName ? 'y' : 'year';

    if (planName.toLocaleLowerCase().includes(Month)) {
      result = price / (period * 31);
    }
    if (planName.toLocaleLowerCase().includes(Day)) {
      result = price / period;
    }
    if (planName.toLocaleLowerCase().includes(Year)) {
      result = price / (period * 365);
    }

    return result.toFixed(2);
  }
}

export const plansUtils = new PlansUtils();
