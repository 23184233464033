import styled from 'styled-components'

export const Card = styled.div`
    width: 100%;
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 5px 20px 0 #4532780d;
    color: var(--black-color);
    overflow: hidden;
    filter: drop-shadow(0px 5px 20px rgba(69, 50, 120, 0.05));
`

export const Title = styled.h2`
    font-family: Avenir600;
    font-size: 20px;
    margin: 0;
`

export const Description = styled.p`
    margin-top: 5px;
    font-family: Avenir400;
    font-size: 14px;
`

export const Certification = styled.div`
    max-width: max-content;
    font-family: Avenir600;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 8px 10px;
    background: var(--grey-light-color);
    border-radius: 50px;
`

export const Verification = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
`

export const VerifiedBy = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    border-radius: 50px;
    font-family: Avenir400;
    gap: 2px;
    font-size: 12px;
    border: 1px solid var(--gold-color);

    strong {
        font-family: Avenir600;
    }
`

export const VerifiedByText = styled.span`
    display: flex;
    align-items: center;
    gap: 4px;
`

export const PassedCount = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-family: Avenir400;
    font-size: 12px;
    padding: 8px 10px;
    border-radius: 50px;
    border: 1px solid var(--grey-color);

    strong {
        font-family: Avenir600;
    }
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    font-family: Avenir600;
    font-size: 16px;
    color: white;
    padding: 16px 20px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    background: var(--primary-color);
    margin-top: 15px;

    svg {
        pointer-events: none;
    }
`
export const DisabledButton = styled(Button)`
    background-color: var(--black-light-color);
    cursor: default;
`

export const ImageWrapper = styled.div`
    padding-top: 81.6%;
    margin: 10px -20px -20px;
    position: relative;
`

export const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`
