import React from 'react'
import * as S from './GameHeader.style'

export interface GameHeaderProps {
    onClick: () => void
}

const GameHeader: React.FC<GameHeaderProps> = ({ onClick }) => {
    return (
        <S.Header onClick={onClick}>
            <img
                style={{
                    position: 'absolute',
                    left: '20px',
                    cursor: 'pointer',
                }}
                src={'/cognifi/svg/arrowBack.svg'}
                alt="arrow back"
            ></img>

            <img
                width={'108px'}
                src={'/cognifi/svg/logo.svg'}
                alt="logo"
                style={{
                    cursor: 'pointer',
                }}
            ></img>
        </S.Header>
    )
}

export default GameHeader
