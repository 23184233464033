import React from 'react'

import { Box, Modal, SxProps, Theme, useMediaQuery } from '@mui/material'

function BaseModal({
    isModalOpen,
    setModalOpen,
    showClose = true,
    children,
    sxModal,
}: {
    sxModal?: SxProps<Theme>
    isModalOpen: boolean
    showClose?: boolean
    setModalOpen: (state: boolean) => void
    children: JSX.Element
}) {
    const isMobile = useMediaQuery('(max-width:600px)')

    return (
        <Modal open={isModalOpen}>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    overflow: 'auto',
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? '100%' : 'calc(100% - 40px)',
                        maxWidth: isMobile ? '100%' : '430px',
                        margin: isMobile ? 'auto 0 0 0' : 'auto',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            borderRadius: '20px',
                            marginTop: isMobile ? '40px' : undefined,
                            borderBottomLeftRadius: isMobile ? 0 : undefined,
                            borderBottomRightRadius: isMobile ? 0 : undefined,
                            textAlign: 'center',
                            position: 'relative',
                            padding: '30px 20px 20px',
                            '& .close': {
                                position: 'absolute',
                                top: isMobile ? '20px' : '24px',
                                right: isMobile ? '20px' : '24px',
                                cursor: 'pointer',
                                '&:hover': {
                                    path: {
                                        stroke: '#8C8E96',
                                    },
                                },
                            },
                            ...(sxModal ?? {}),
                        }}
                    >
                        {showClose && (
                            <img
                                src="/cognifi/svg/close.svg"
                                className="close"
                                onClick={() => {
                                    setModalOpen(false)
                                }}
                                alt="close"
                            />
                        )}
                        {children}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default BaseModal
