import { useEffect } from 'react'
import GameHeader from '../components/GameHeader'

export default function BlockPuzzle({
    setLoading,
    onClose,
}: {
    setLoading: (v: boolean) => void
    onClose: () => void
}) {
    useEffect(() => {
        document.body.style.overflow = 'hidden'

        const handleIframeMessage = (event: MessageEvent) => {
            if (event.data === 'game_loaded') {
                setLoading(false)
            }
        }
        window.addEventListener('message', handleIframeMessage)
        return () => {
            window.removeEventListener('message', handleIframeMessage)
            document.body.style.overflow = 'auto'

        }
    }, [setLoading])

    return (
        <div
            style={{
                position: 'fixed',
                zIndex: 50,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: 'white',
            }}
        >
            <GameHeader onClick={onClose} />
            <iframe
                title="Block Puzzle"
                style={{
                    border: 'none',
                    width: '100%',
                    height: '100%',
                    userSelect: 'none',
                }}
                src={'/cognifi/games/block_puzzle/index.html'}
            ></iframe>
        </div>
    )
}
