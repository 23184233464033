export const IMAGE_PATHS = [
    "svg/analysis_checkmark.svg",
    "svg/analysis_unchecked.svg",
    "svg/app_store.svg",
    "svg/apple-logo.svg",
    "svg/apple_pay.svg",
    "svg/apple_pay_dark.svg",
    "svg/apple_pay_white.svg",
    "svg/arrowBack.svg",
    "svg/arrow_left.svg",
    "svg/arrow_micro.svg",
    "svg/arrow_right.svg",
    "svg/arrow_skip.svg",
    "svg/bottom-cap.svg",
    "svg/calendar.svg",
    "svg/checked_icon_green.svg",
    "svg/checkmark.svg",
    "svg/chevron.svg",
    "svg/close.svg",
    "svg/credit_card.svg",
    "svg/credit_card_pay.svg",
    "svg/discount.svg",
    "svg/fire.svg",
    "svg/fire_red.svg",
    "svg/google-logo.svg",
    "svg/google_pay.svg",
    "svg/google_play.svg",
    "svg/green_shield.svg",
    "svg/into_checkmark.svg",
    "svg/intro_sign.svg",
    "svg/logo.svg",
    "svg/mail.svg",
    "svg/offer/p1.svg",
    "svg/offer/p10.svg",
    "svg/offer/p11.svg",
    "svg/offer/p2.svg",
    "svg/offer/p3.svg",
    "svg/offer/p4.svg",
    "svg/offer/p5.svg",
    "svg/offer/p6.svg",
    "svg/offer/p7.svg",
    "svg/offer/p8.svg",
    "svg/offer/p9.svg",
    "svg/pack_arrow.svg",
    "svg/progress_circle_gray.svg",
    "svg/results_leaf.svg",
    "svg/review_stars.svg",
    "svg/review_stars_green.svg",
    "svg/shield.svg",
    "svg/sign_in_step_icon.svg",
    "svg/stopwatch.svg",
    "svg/subs_checked_pack.svg",
    "svg/subs_checkmark.svg",
    "svg/subs_plan.svg",
    "svg/weight_red.svg",
    "images/gradient_background.webp",
    "images/main/arrow_icon.webp",
    "images/main/belgium.webp",
    "images/main/brazil.webp",
    "images/main/chart_image.webp",
    "images/main/cloud_memory.webp",
    "images/main/group_1.webp",
    "images/main/group_2.webp",
    "images/main/group_3.webp",
    "images/main/group_4.webp",
    "images/main/group_5.webp",
    "images/main/puzzle_piece.webp",
    "images/main/slovenia.webp",
    "images/main/south_korea.webp",
    "images/main/united_kingdom.webp",
    "images/person_1.webp",
    "images/person_2.webp",
    "images/person_3.webp",
    "images/subs_image_1.webp",
    "images/subs_image_2.webp",
    "images/subs_image_3.webp",
    "images/subscription/image_1.webp",
    "images/subscription/image_2.webp",
    "images/subscription/image_3.webp",
    "images/subscription/image_4.webp",
    "images/subscription/offline_access.webp",
    "images/subscription/puzzle_piece.webp",
    "images/subscription/report_chart.webp",
    "images/subscription_image.webp"
]

export const QUIZ_IMAGE_PATHS = [
    "images/intro_background.webp",
    "images/quiz/answer_10_1.webp",
    "images/quiz/answer_10_2_correct_.webp",
    "images/quiz/answer_10_3.webp",
    "images/quiz/answer_10_4.webp",
    "images/quiz/answer_11_1.webp",
    "images/quiz/answer_11_2.webp",
    "images/quiz/answer_11_3.webp",
    "images/quiz/answer_11_4_correct_.webp",
    "images/quiz/answer_12_1.webp",
    "images/quiz/answer_12_2_correct_.webp",
    "images/quiz/answer_12_3.webp",
    "images/quiz/answer_12_4.webp",
    "images/quiz/answer_13_1.webp",
    "images/quiz/answer_13_2_correct_.webp",
    "images/quiz/answer_13_3.webp",
    "images/quiz/answer_13_4.webp",
    "images/quiz/answer_14_1.webp",
    "images/quiz/answer_14_2.webp",
    "images/quiz/answer_14_3.webp",
    "images/quiz/answer_14_4_correct_.webp",
    "images/quiz/answer_15_1.webp",
    "images/quiz/answer_15_2_correct_.webp",
    "images/quiz/answer_15_3.webp",
    "images/quiz/answer_15_4.webp",
    "images/quiz/answer_16_1.webp",
    "images/quiz/answer_16_2.webp",
    "images/quiz/answer_16_3.webp",
    "images/quiz/answer_16_4_correct_.webp",
    "images/quiz/answer_17_1.webp",
    "images/quiz/answer_17_2.webp",
    "images/quiz/answer_17_3_correct_.webp",
    "images/quiz/answer_17_4.webp",
    "images/quiz/answer_18_1.webp",
    "images/quiz/answer_18_2_correct_.webp",
    "images/quiz/answer_18_3.webp",
    "images/quiz/answer_18_4.webp",
    "images/quiz/answer_19_1.webp",
    "images/quiz/answer_19_2.webp",
    "images/quiz/answer_19_3.webp",
    "images/quiz/answer_19_4_correct_.webp",
    "images/quiz/answer_1_1.webp",
    "images/quiz/answer_1_2.webp",
    "images/quiz/answer_1_3_correct_.webp",
    "images/quiz/answer_1_4.webp",
    "images/quiz/answer_20_1_correct_.webp",
    "images/quiz/answer_20_2.webp",
    "images/quiz/answer_20_3.webp",
    "images/quiz/answer_20_4.webp",
    "images/quiz/answer_2_1.webp",
    "images/quiz/answer_2_2.webp",
    "images/quiz/answer_2_3_correct_.webp",
    "images/quiz/answer_2_4.webp",
    "images/quiz/answer_3_1.webp",
    "images/quiz/answer_3_2_correct_.webp",
    "images/quiz/answer_3_3.webp",
    "images/quiz/answer_3_4.webp",
    "images/quiz/answer_4_1_correct_.webp",
    "images/quiz/answer_4_2.webp",
    "images/quiz/answer_4_3.webp",
    "images/quiz/answer_4_4.webp",
    "images/quiz/answer_5_1.webp",
    "images/quiz/answer_5_2.webp",
    "images/quiz/answer_5_3.webp",
    "images/quiz/answer_5_4_correct_.webp",
    "images/quiz/answer_6_1_correct_.webp",
    "images/quiz/answer_6_2.webp",
    "images/quiz/answer_6_3.webp",
    "images/quiz/answer_6_4.webp",
    "images/quiz/answer_7_1.webp",
    "images/quiz/answer_7_2.webp",
    "images/quiz/answer_7_3_correct_.webp",
    "images/quiz/answer_7_4.webp",
    "images/quiz/answer_8_1.webp",
    "images/quiz/answer_8_2.webp",
    "images/quiz/answer_8_3.webp",
    "images/quiz/answer_8_4_correct_.webp",
    "images/quiz/answer_9_1.webp",
    "images/quiz/answer_9_2.webp",
    "images/quiz/answer_9_3_correct_.webp",
    "images/quiz/answer_9_4.webp",
    "images/quiz/question_question_1.webp",
    "images/quiz/question_question_10.webp",
    "images/quiz/question_question_11.webp",
    "images/quiz/question_question_12.webp",
    "images/quiz/question_question_13.webp",
    "images/quiz/question_question_14.webp",
    "images/quiz/question_question_15.webp",
    "images/quiz/question_question_16.webp",
    "images/quiz/question_question_17.webp",
    "images/quiz/question_question_18.webp",
    "images/quiz/question_question_19.webp",
    "images/quiz/question_question_2.webp",
    "images/quiz/question_question_20.webp",
    "images/quiz/question_question_3.webp",
    "images/quiz/question_question_4.webp",
    "images/quiz/question_question_5.webp",
    "images/quiz/question_question_6.webp",
    "images/quiz/question_question_7.webp",
    "images/quiz/question_question_8.webp",
    "images/quiz/question_question_9.webp",
]

export const WEBAPP_IMAGE_PATHS = [
    "/cognifi/images/gradient_background_fullscreen.webp",
    "/cognifi/images/webapp/articles/5-morning-habits-to-boost-your-brain-power-all-day.webp",
    "/cognifi/images/webapp/articles/balancing-work-and-brain-health-how-to-protect-your-cognitive-function-in-a-fast-paced-world.webp",
    "/cognifi/images/webapp/articles/brain-boosting-foods-what-to-eat-for-better-focus-and-memory.webp",
    "/cognifi/images/webapp/articles/brain-training-games-can-they-really-improve-cognitive-performance.webp",
    "/cognifi/images/webapp/articles/cognitive-functions-101-how-memory-reasoning-and-problem-solving-shape-your-intelligence.webp",
    "/cognifi/images/webapp/articles/digital-detox-how-reducing-screen-time-can-boost-cognitive-health.webp",
    "/cognifi/images/webapp/articles/harnessing-the-power-of-music-to-improve-brain-function.webp",
    "/cognifi/images/webapp/articles/how-emotions-influence-intelligence-the-role-of-emotional-intelligence-in-cognitive-performance.webp",
    "/cognifi/images/webapp/articles/how-exercise-enhances-your-brain-the-neuroscience-of-physical-activity.webp",
    "/cognifi/images/webapp/articles/how-hydration-impacts-brain-function-the-link-between-water-and-cognitive-performance.webp",
    "/cognifi/images/webapp/articles/how-regular-exercise-improves-brain-health-and-longevity.webp",
    "/cognifi/images/webapp/articles/image_1.webp",
    "/cognifi/images/webapp/articles/image_2.webp",
    "/cognifi/images/webapp/articles/managing-stress-how-lowering-cortisol-levels-can-protect-your-brain.webp",
    "/cognifi/images/webapp/articles/mental-health-and-brain-health-how-your-mind-impacts-your-brain.webp",
    "/cognifi/images/webapp/articles/mindfulness-for-a-sharper-mind-simple-practices-to-increase-focus-and-calm.webp",
    "/cognifi/images/webapp/articles/neuroplasticity-at-any-age-how-you-can-keep-your-brain-sharp-for-life.webp",
    "/cognifi/images/webapp/articles/problem-solving-and-the-brain-how-neural-networks-help-you-find-solutions.webp",
    "/cognifi/images/webapp/articles/reasoning-and-logic-the-neuroscience-behind-critical-thinking-skills.webp",
    "/cognifi/images/webapp/articles/sleep-and-your-brain-why-rest-is-essential-for-cognitive-function.webp",
    "/cognifi/images/webapp/articles/the-benefits-of-meditation-how-mindfulness-protects-and-strengthens-your-brain.webp",
    "/cognifi/images/webapp/articles/the-future-of-intelligence-what-neuroscience-tells-us-about-enhancing-iq-through-technology.webp",
    "/cognifi/images/webapp/articles/the-impact-of-smoking-and-alcohol-on-brain-health.webp",
    "/cognifi/images/webapp/articles/the-memory-intelligence-connection-why-strong-recall-makes-you-smarter.webp",
    "/cognifi/images/webapp/articles/the-power-of-short-breaks-how-micro-rest-can-sharpen-your-focus.webp",
    "/cognifi/images/webapp/articles/the-power-of-sleep-how-to-optimize-rest-for-cognitive-performance.webp",
    "/cognifi/images/webapp/articles/the-role-of-neuroplasticity-how-to-rewire-your-brain-for-smarter-thinking.webp",
    "/cognifi/images/webapp/articles/the-role-of-nutrition-in-brain-health-what-should-you-eat.webp",
    "/cognifi/images/webapp/articles/the-science-of-learning-new-skills-how-to-train-your-brain-for-continuous-growth.webp",
    "/cognifi/images/webapp/articles/training-your-brain-how-cognitive-exercises-improve-iq-and-learning.webp",
    "/cognifi/images/webapp/articles/understanding-the-neuroscience-of-intelligence-what-makes-a-brain-smart.webp",
    "/cognifi/images/webapp/articles/why-social-interaction-is-key-to-a-healthy-brain.webp",
    "/cognifi/images/webapp/compare/1.webp",
    "/cognifi/images/webapp/compare/10.webp",
    "/cognifi/images/webapp/compare/11.webp",
    "/cognifi/images/webapp/compare/12.webp",
    "/cognifi/images/webapp/compare/13.webp",
    "/cognifi/images/webapp/compare/14.webp",
    "/cognifi/images/webapp/compare/2.webp",
    "/cognifi/images/webapp/compare/3.webp",
    "/cognifi/images/webapp/compare/4.webp",
    "/cognifi/images/webapp/compare/5.webp",
    "/cognifi/images/webapp/compare/6.webp",
    "/cognifi/images/webapp/compare/7.webp",
    "/cognifi/images/webapp/compare/8.webp",
    "/cognifi/images/webapp/compare/9.webp",
    "/cognifi/images/webapp/countries/bg.svg",
    "/cognifi/images/webapp/countries/br.svg",
    "/cognifi/images/webapp/countries/sk.svg",
    "/cognifi/images/webapp/countries/sv.svg",
    "/cognifi/images/webapp/countries/uk.svg",
    "/cognifi/images/webapp/games/block_puzzle.webp",
    "/cognifi/images/webapp/games/fruit_merge.webp",
    "/cognifi/images/webapp/games/games_banner.webp",
    "/cognifi/images/webapp/games/logic_puzzles.webp",
    "/cognifi/images/webapp/games/mahjong_farm.webp",
    "/cognifi/images/webapp/games/word.webp",
    "/cognifi/images/webapp/iq-tests/Intro_background_small.webp",
    "/cognifi/images/webapp/iq-tests/average-iq.svg",
    "/cognifi/images/webapp/iq-tests/card/card-1.webp",
    "/cognifi/images/webapp/iq-tests/card/card-2.webp",
    "/cognifi/images/webapp/iq-tests/card/card-3.webp",
    "/cognifi/images/webapp/iq-tests/card/card-4.webp",
    "/cognifi/images/webapp/profile/chart.webp",
    "/cognifi/images/webapp/profile/star.webp",
    "/cognifi/images/unsubscribe/arrow_icon.webp",
    "/cognifi/images/unsubscribe/canceled.svg",
    "/cognifi/images/unsubscribe/heart.webp",
    "/cognifi/images/unsubscribe/svg1.svg",
    "/cognifi/images/unsubscribe/svg2.svg",
    "/cognifi/images/unsubscribe/svg3.svg",

]

