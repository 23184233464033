import React, { ReactNode } from 'react'
import * as S from './GameCard.style'
import GameIcon from '@assets/svg/webapp/games/game_icon.svg'
import GameDisabledIcon from '@assets/svg/webapp/games/game_disabled_icon.svg'

export interface GameCardProps {
    title: string
    description: string
    badgeIcon: ReactNode
    gameImage: string
    badgeColor: string
    badgeText: string
    isComingSoon: boolean
    onButtonClick: () => void
}

const GameCard: React.FC<GameCardProps> = ({
    title,
    description,
    badgeIcon,
    gameImage,
    badgeColor,
    badgeText,
    isComingSoon,
    onButtonClick,
}) => {
    return (
        <S.Card>
            <S.TopWrapper>
                <S.Image src={gameImage} />
                <S.Badge $color={badgeColor}>
                    {badgeIcon} {badgeText}
                </S.Badge>
            </S.TopWrapper>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>

            {isComingSoon ? (
                <S.DisabledButton>
                    <GameDisabledIcon />
                    Coming soon
                </S.DisabledButton>
            ) : (
                <S.Button onClick={onButtonClick}>
                    <GameIcon />
                    Play game
                </S.Button>
            )}
        </S.Card>
    )
}

export default GameCard
