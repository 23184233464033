import { styled } from 'styled-components'

export const NavWrapper = styled.div<{$isInstaBrowser: boolean}>`
    position: sticky;
    bottom: 0;
    border-top: 1px solid var(--black-10-color);
    padding: 5px;
    background: white;
    z-index: 9;
    ${p=>p.$isInstaBrowser && `
        padding-bottom: 20px;
    `}
`

export const Nav = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

export const SvgWrapper = styled.div`
    padding: 4px 15px;
    border-radius: 20px;
`

export const NavItem = styled.div`
    flex: 1;

    .nav-link {
        font-family: Avenir500;
        font-size: 12px;
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: var(--black-light-color);

        svg {
            pointer-events: none;
        }

        &.active {
            color: var(--primary-color);

            svg {
                fill: var(--primary-color);
            }

            ${SvgWrapper} {
                background: var(--purple-light-color);
            }
        }
    }
`

export const NavText = styled.span``
