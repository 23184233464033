import { styled } from 'styled-components'
import { ReactNode } from 'react'
import Navigation from '@components/WebApp/components/Navigation'
import Header from '@components/WebApp/components/Header'
export const PageWrapper = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
`

export const PageDescription = styled.span`
    margin-top: 10px;
    font-family: Avenir400;
    font-size: 16px;
    color: var(--black-50-color);

    strong {
        font-family: Avenir600;
        color: var(--primary-color);
    }
`

export const PageTitle = styled.span`
    display: inline-block;
    font-family: Avenir600;
    font-size: 24px;

    b {
        color: var(--primary-color);
    }

    &.center {
        text-align: center;
    }
`

export const GradientImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 1;
`

export const BasePage = ({
    children,
    hideNavigation,
}: {
    children: ReactNode
    hideNavigation?: boolean
}) => (
    <>
        {children}
        {!hideNavigation && <Navigation />}
    </>
)
