import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';

import { trackEvent } from '@api/requests';
import Email from './Steps/Email';
import Intro from './Steps/Intro';
import Question from './Steps/Question';
import Analysis from './Steps/Analysis';

const getDefaultStep = (stepNumber: number) => {
  window.scrollTo(0, 0);

  switch (stepNumber) {
    case 1: {
      return <Intro />;
    }
    case 2: {
      return <Question questionNumber={1} />;
    }
    case 3: {
      return <Question questionNumber={2} />;
    }
    case 4: {
      return <Question questionNumber={3} />;
    }
    case 5: {
      return <Question questionNumber={4} />;
    }
    case 6: {
      return <Question questionNumber={5} />;
    }
    case 7: {
      return <Question questionNumber={6} />;
    }
    case 8: {
      return <Question questionNumber={7} />;
    }
    case 9: {
      return <Question questionNumber={8} />;
    }
    case 10: {
      return <Question questionNumber={9} />;
    }
    case 11: {
      return <Question questionNumber={10} />;
    }
    case 12: {
      return <Question questionNumber={11} />;
    }
    case 13: {
      return <Question questionNumber={12} />;
    }
    case 14: {
      return <Question questionNumber={13} />;
    }
    case 15: {
      return <Question questionNumber={14} />;
    }
    case 16: {
      return <Question questionNumber={15} />;
    }
    case 17: {
      return <Question questionNumber={16} />;
    }
    case 18: {
      return <Question questionNumber={17} />;
    }
    case 19: {
      return <Question questionNumber={18} />;
    }
    case 20: {
      return <Question questionNumber={19} />;
    }
    case 21: {
      return <Question questionNumber={20} />;
    }
    case 22: {
      return <Analysis />;
    }
    case 23: {
      return <Email />;
    }
  }
};

// const getShortStep = (stepNumber: number, setStep: (arg: number) => void) => {
//   window.scrollTo(0, 0);

//   switch (stepNumber) {
//     case 1: {
//       return <Age stepNumber={1} stepsNumber={7} backToMain />;
//     }
//     case 2: {
//       return <Goal stepNumber={2} stepsNumber={7} />;
//     }
//     case 3: {
//       return <Lunch stepNumber={3} stepsNumber={7} />;
//     }
//     case 4: {
//       return <Environment />;
//     }
//     case 5: {
//       return <TargetZones stepNumber={4} stepsNumber={7} />;
//     }
//     case 6: {
//       return <Height stepNumber={5} stepsNumber={7} />;
//     }
//     case 7: {
//       return <Weight stepNumber={6} stepsNumber={7} />;
//     }
//     case 8: {
//       return <TargetWeight stepNumber={7} stepsNumber={7} />;
//     }
//     case 9: {
//       return <Processing />;
//     }
//     case 10: {
//       return <Email />;
//     }
//   }
// };

function Quiz() {
  const { step, userLocation, setStep } = useQuizState();

  const mode = new URLSearchParams(window.location.search).get('mode');
  const steps = useMemo(() => {
    switch (mode) {
      // case 'short': {
      //   return getShortStep(step, setStep);
      // }
      default: {
        return getDefaultStep(step);
      }
    }
  }, [step, mode, setStep]);

  const getScreenName = (mode: string | null, step: number) => {
    switch (mode) {
      case 'short': {
        return `Step ${step} shown`;
      }
      default: {
        if (step === 1) return 'Quiz intro screen shown';
        if (step === 22) return 'Analysis screen shown';
        if (step === 23) return 'Email screen shown';
        return `Question ${step - 1} shown`;
      }
    }
  };

  useEffect(() => {
    trackEvent(getScreenName(mode, step), userLocation);
  }, [step, mode]);

  return (
    <Box
      sx={{
        minHeight: '100dvh',
        height: '100%',
      }}
    >
      {steps}
    </Box>
  );
}

export default Quiz;
