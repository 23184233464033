import * as S from './Report.style'
import BrLeft from '@assets/svg/webapp/report/br-left.svg'
import BrRight from '@assets/svg/webapp/report/br-right.svg'
import PointIcon from '@assets/svg/webapp/report/point.svg'
import BrainIcon from '@assets/svg/webapp/report/brain.svg'
import CheckmarkIcon from '@assets/svg/webapp/report/checkmark.svg'
import LampIcon from '@assets/svg/webapp/report/lamp.svg'
import FaceIcon from '@assets/svg/webapp/report/face.svg'
import { ReactNode, useEffect } from 'react'
import { gerProfileReport } from '@api/requests'
import { SubGroup } from '@models/webapp'
import { ProfileMainItem } from '@components/WebApp/pages/Profile/Profile'
import { BasePage } from '@components/WebApp/pages/common.style'
import Loader from '@components/WebApp/components/Loader'
import { useWebAppState } from '@components/WebApp/WebApp'
import { CreateCertificateDrawer } from '@components/WebApp/pages/Profile/components'
import { getBaseAssetsUrl } from '@utils/webapp'
import { useUser } from '@providers/UserProvider'
import { firebaseAuth } from '@services/firebase'
import { Routes } from '../../../../App'
import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

const SubItem = ({
    children,
    type,
}: {
    type: SubGroup['type']
    children: ReactNode
}) => {
    const getIcon = () => {
        switch (type) {
            case 'strengths':
                return <BrainIcon />
            case 'applications':
                return <CheckmarkIcon />
            case 'recommendations_for_development':
                return <LampIcon />
        }
    }
    return (
        <S.SubItem className={type}>
            <S.SubItemCol>{getIcon()}</S.SubItemCol>
            <S.SubItemText>{children}</S.SubItemText>
        </S.SubItem>
    )
}

export default function Report() {
    const {
        report,
        reportLoading,
        openIqCertificate,
        setOpenIqCertificate,
        setReport,
        setReportLoading,
    } = useWebAppState()

    const navigate = useNavigate()

    const { profile, setUser } = useUser()

    useEffect(() => {
        if (report) return

        if (firebaseAuth.currentUser) {
            setReportLoading(true)

            firebaseAuth.currentUser
                .getIdToken(true)
                .then((token) => {
                    gerProfileReport(firebaseAuth.currentUser?.uid ?? '', token)
                        .then((response) => {
                            setReport(response)
                        })
                        .catch(console.error)
                        .finally(() => setReportLoading(false))
                })
                .catch((err) => {
                    console.error(err)
                    setReportLoading(false)
                })
        } else {
            navigate(Routes.SIGN_IN)
            signOut(firebaseAuth).catch(console.error)
            setUser(null)
        }
    }, [report])

    if (!report || !report.report || reportLoading) {
        return <Loader position="center" />
    }

    return (
        <BasePage>
            <S.StyledPageWrapper>
                <S.StyledPageTitle className="center">
                    Your IQ score
                </S.StyledPageTitle>
                <S.IqScore>
                    <BrLeft />
                    <S.IqValue> {report.report.iq_score}</S.IqValue>
                    <BrRight />
                </S.IqScore>
                <S.Description
                    className="black-50-color"
                    style={{ margin: '10px 0 0', textAlign: 'center' }}
                >
                    {report.report.description}
                </S.Description>

                {Object.entries(report.report.sections).map(([key, item]) => {
                    return (
                        <S.GroupPoint>
                            <S.Point>
                                <PointIcon />
                                <S.PointValue>{item.point}</S.PointValue>
                            </S.Point>
                            <S.GroupItem>
                                <S.Title> {item.title}</S.Title>
                                <S.Description>
                                    {item.description}
                                </S.Description>
                            </S.GroupItem>
                            {item.sub_groups &&
                                item.sub_groups.map((subItem, index) => (
                                    <S.SubGroup>
                                        <S.SubTitle>
                                            {subItem.sub_title}
                                        </S.SubTitle>
                                        <S.SubItems>
                                            {subItem.items.map((sub) => (
                                                <SubItem type={subItem.type}>
                                                    {sub}
                                                </SubItem>
                                            ))}
                                        </S.SubItems>
                                    </S.SubGroup>
                                ))}
                        </S.GroupPoint>
                    )
                })}

                <S.Footer>
                    <S.IconWrapper>
                        <FaceIcon />
                    </S.IconWrapper>
                    <S.Title style={{ margin: '20px 0 0' }}>
                        {report.report.footer.title}
                    </S.Title>
                    <S.Description style={{ margin: '20px 0 0' }}>
                        {report.report.footer.description}
                    </S.Description>
                    <S.SubGroup>
                        <S.SubTitle>
                            {
                                report.report.footer.recommendations_for_growth
                                    .title
                            }
                        </S.SubTitle>
                        <S.SubItems>
                            {report.report.footer.recommendations_for_growth.items.map(
                                (v) => (
                                    <SubItem type="recommendations_for_development">
                                        {v}
                                    </SubItem>
                                )
                            )}
                        </S.SubItems>
                    </S.SubGroup>
                    <S.FooterText>{report.report.footer.text}</S.FooterText>
                    <ProfileMainItem
                        img={'/cognifi/images/webapp/profile/star.webp'}
                        title={'IQ certificate'}
                        text={
                            'Your certificate is ready for one-click download'
                        }
                        onClick={() =>
                            profile?.certificate_path
                                ? window.open(
                                      getBaseAssetsUrl(
                                          profile?.certificate_path
                                      ),
                                      '_blank'
                                  )
                                : setOpenIqCertificate(true)
                        }
                    />
                </S.Footer>
            </S.StyledPageWrapper>
            <CreateCertificateDrawer
                open={openIqCertificate}
                onClose={() => setOpenIqCertificate(false)}
            />
        </BasePage>
    )
}
