import CanadaIcon from '@assets/svg/webapp/tests/country/canada.svg'
import EuropaIcon from '@assets/svg/webapp/tests/country/europa.svg'
import UsaIcon from '@assets/svg/webapp/tests/country/usa.svg'
import AustraliaIcon from '@assets/svg/webapp/tests/country/australia.svg'
import WorldIcon from '@assets/svg/webapp/tests/country/worldwide.svg'

import { TestCardProps } from '@components/WebApp/pages/Tests/components/TestCard/TestCard'
import { ResultCardProps } from '@components/WebApp/pages/Tests/components/ResultCard/ResultCard'

export const TESTS: Array<Omit<TestCardProps, 'onButtonClick'>> = [
    {
        title: 'Cognifi Official Test',
        description:
            'Evaluate your mental agility with tasks designed to measure your quick thinking and problem-solving under pressure.',
        countryIcon: <WorldIcon />,
        verificationLabel: 'Worldwide popular test',
        verifiedBy: '<strong>Verified</strong>by Cognifi',
        passedCount: 49126,
        buttonText: 'Start Certified Test',
        img: '/cognifi/images/webapp/iq-tests/card/card-1.webp',
        isComingSoon: false,
        isVerifiedByCognifi: true,
        testName: 'cognifi_official',
    },
    {
        title: 'Brain Power Evaluation',
        description:
            'Assess your reasoning abilities with puzzles that challenge your critical thinking and cognitive flexibility.',
        countryIcon: <EuropaIcon />,
        verificationLabel: 'Recognized in Europe',
        verifiedBy: '<strong>Verified</strong>by Cognifi',
        passedCount: 57514,
        buttonText: 'Start Certified Test',
        img: '/cognifi/images/webapp/iq-tests/card/card-2.webp',
        isComingSoon: false,
        isVerifiedByCognifi: true,
        testName: 'brain_evaluation',
    },
    {
        title: 'Logical Thinking Exam',
        description:
            'Test logic and deduction skills through structured questions that analyze situations and draw conclusions.',
        countryIcon: <UsaIcon />,
        verificationLabel: 'Official test in USA',
        verifiedBy: '<strong>Verified</strong>by Cognifi',
        passedCount: 64256,
        buttonText: 'Start Certified Test',
        img: '/cognifi/images/webapp/iq-tests/card/card-3.webp',
        isComingSoon: true,
        isVerifiedByCognifi: false,
        testName: '',
    },
    {
        title: 'Mental Acuity Test',
        description:
            'Gauge mental sharpness by answering questions focused on memory, attention, and cognitive speed.',
        countryIcon: <AustraliaIcon />,
        verificationLabel: 'Standardized in Australia',
        verifiedBy: '<strong>Verified</strong>by Cognifi',
        passedCount: 21957,
        buttonText: 'Start Certified Test',
        img: '/cognifi/images/webapp/iq-tests/card/card-4.webp',
        isComingSoon: true,
        isVerifiedByCognifi: false,
        testName: '',
    },
]

export const USER_RESULTS: Array<ResultCardProps> = [
    {
        img: '/cognifi/images/webapp/countries/uk.svg',
        name: 'Robert Fox',
        date: 'a min ago',
        iq: 87,
    },
    {
        img: '/cognifi/images/webapp/countries/br.svg',
        name: 'Maria Clara',
        date: 'a min ago',
        iq: 99,
    },
    {
        img: '/cognifi/images/webapp/countries/sk.svg',
        name: 'DoYoon Choi',
        date: 'a min ago',
        iq: 83,
    },
    {
        img: '/cognifi/images/webapp/countries/sv.svg',
        name: 'Tomaž Seliè',
        date: 'a min ago',
        iq: 105,
    },
    {
        img: '/cognifi/images/webapp/countries/bg.svg',
        name: 'Matthijs Holvoet',
        date: '2 min ago',
        iq: 118,
    },
]
