import { styled } from 'styled-components'

export const IntroImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const ImageWrapper = styled.div`
    position: relative;
    padding-top: 91.5%;
    margin: 20px -20px 20px;
`

export const Cards = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const Inner = styled.div`
    margin: 40px -20px -20px;
    padding: 40px 20px 40px;
    background: white;
`

export const LastResults = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const Experts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const ExpertsGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`

export const LargeTitle = styled.span`
    font-family: Avenir700;
    font-size: 24px;
    white-space: pre-wrap;
    text-align: center;
`

export const Description = styled.span`
    display: inline-block;
    font-family: Avenir400;
    font-size: 16px;
    color: var(--black-50-color);
    text-align: center;
`

export const AverageIqImage = styled.img`
    margin-top: 20px;
    max-width: 100%;
    width: 100%;
`
