import { Routes } from "../App";

export const isBrowser: boolean = typeof window !== 'undefined';

function detectBrowserByUserAgent(matchString: string): boolean {
    if (isBrowser) {
        try {
            return window.navigator.userAgent.includes(matchString);
        } catch (e) {
            console.error(e);
        }
    }
    return false;
}

/**
 * Check if Instagram webview browser
 */
export const isInstagram: boolean = detectBrowserByUserAgent('Instagram');

/**
 * Check if Facebook webview browser
 */
export const isFacebook: boolean = detectBrowserByUserAgent('FBAV') || detectBrowserByUserAgent('FBAN');

/**
 * Detects both Facebook & Instagram in-app browsers
 */
export const isMetaInAppBrowser: boolean = isFacebook || isInstagram;

/**
 * Detect user system
 */
export function getOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    if (macosPlatforms.indexOf(platform) !== -1) {
        return 'mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        return 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'windows';
    } else if (/Android/.test(userAgent)) {
        return 'android';
    } else if (/Linux/.test(platform)) {
        return 'linux';
    }
    return 'other';
}

export const redirectToNativeBrowser = (): void => {
    const isRedirectAppRoute = [Routes.MAIN, Routes.QUIZ].includes(window.location.pathname as any)

    const shouldRedirectAndroid = new URLSearchParams(window.location.search).get('bb_android') === 'true';

    if (shouldRedirectAndroid && isMetaInAppBrowser && isRedirectAppRoute) {
        ShouldOpenAndroidInSystemBrowser();
    }
};

export function ShouldOpenAndroidInSystemBrowser(): void {
    if (isBrowser) {
        switch (getOS()) {
            case 'android':
                window.location.href =
                    'https://redirect.streamspace.club/redirect.php?url=' + encodeURIComponent(window.location.href);

                break;
        }
    }
}
