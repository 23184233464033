import {
    PageDescription,
    PageTitle,
    PageWrapper,
    BasePage,
} from '@components/WebApp/pages/common.style'
import * as S from './Games.style'
import { TimerContainer } from '@components/WebApp/components/Timer'
import GameCard from './components/GameCard'
import { useEffect, useState } from 'react'
import * as animation from '@assets/json/JSON_Cognifi-Logo-Loop.json'
import Lottie from 'react-lottie-player'
import BlockPuzzle from './pages/BlockPuzzle'
import Critical from '@assets/svg/webapp/games/critical.svg'
import Logical from '@assets/svg/webapp/games/logical.svg'
import Verbal from '@assets/svg/webapp/games/verbal.svg'
// import Memory from '@assets/svg/webapp/games/memory.svg'
import { GameCardProps } from './components/GameCard'
import FruitMerge from './pages/FruitMerge'

export default function Games() {
    const [loading, setLoading] = useState(false)
    const [isBlockPuzzleOpened, setBlockPuzzleOpened] = useState(false)
    const [isFruitMergeOpened, setFruitMergeOpened] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const GAMES: Array<GameCardProps> = [
        {
            title: 'Block Puzzle',
            description:
                'Match tiles, clear the board, and sharpen your focus in this timeless puzzle challenge.',
            gameImage: '/cognifi/images/webapp/games/block_puzzle.webp',
            badgeIcon: <Critical />,
            badgeColor: 'rgba(175, 95, 255, 0.10)',
            badgeText: 'Critical thinking',
            isComingSoon: false,
            onButtonClick: () => {
                setLoading(true)
                setBlockPuzzleOpened(true)
            },
        },
        {
            title: 'Fruit Merge',
            description:
                'The game encourages critical thinking and problem-solving, enhancing real-life skills',
            gameImage: '/cognifi/images/webapp/games/fruit_merge.webp',
            badgeIcon: <Critical />,
            badgeColor: 'rgba(175, 95, 255, 0.10)',
            badgeText: 'Critical thinking',
            isComingSoon: false,
            onButtonClick: () => {
                setLoading(true)
                setFruitMergeOpened(true)
            },
        },
        {
            title: 'Mahjong Farm',
            description:
                'Boost your memory by training your brain to focus and recall more effectively',
            gameImage: '/cognifi/images/webapp/games/mahjong_farm.webp',
            badgeIcon: <Logical />,
            badgeColor: 'rgba(102, 57, 230, 0.10)',
            badgeText: 'Logical thinking',
            isComingSoon: true,
            onButtonClick: () => {},
        },
        {
            title: 'Logic Puzzles Pro',
            description:
                'Challenge your problem-solving skills with a series of increasingly complex puzzles',
            gameImage: '/cognifi/images/webapp/games/logic_puzzles.webp',
            badgeIcon: <Logical />,
            badgeColor: 'rgba(102, 57, 230, 0.10)',
            badgeText: 'Logical thinking',
            isComingSoon: true,
            onButtonClick: () => {},
        },
        {
            title: 'Word Whiz',
            description:
                'Expand your vocabulary and improve verbal intelligence by solving challenges',
            gameImage: '/cognifi/images/webapp/games/word.webp',
            badgeIcon: <Verbal />,
            badgeColor: 'rgba(237, 110, 239, 0.10)',
            badgeText: 'Verbal skills',
            isComingSoon: true,
            onButtonClick: () => {},
        },
    ]

    return (
        <>
            <BasePage>
                <PageWrapper>
                    <PageTitle>Games</PageTitle>
                    <PageDescription>
                        We've created an{' '}
                        <strong>interactive brain warm-up.</strong> Play and
                        grow your skills simultaneously!
                    </PageDescription>

                    <S.ImageWrapper>
                        <S.Image
                            src="/cognifi/images/webapp/games/games_banner.webp"
                            alt="games"
                        />
                    </S.ImageWrapper>

                    {/* <TimerContainer targetUnixTime={1729388741} /> */}

                    <S.Games>
                        {GAMES.map((game) => (
                            <GameCard key={game.title} {...game} />
                        ))}
                    </S.Games>

                    {loading && (
                        <S.LoadingWrapper>
                            <Lottie
                                play
                                animationData={animation}
                                style={{
                                    width: '105px',
                                    height: '78px',
                                }}
                            />
                            <S.LoadingTitle>Game is loading</S.LoadingTitle>
                            <S.LoadingDescription>
                                Preparing your brain for overthinking!
                            </S.LoadingDescription>
                        </S.LoadingWrapper>
                    )}
                </PageWrapper>
            </BasePage>
            {isBlockPuzzleOpened && (
                <BlockPuzzle
                    setLoading={setLoading}
                    onClose={() => {
                        setBlockPuzzleOpened(false)
                    }}
                />
            )}

            {isFruitMergeOpened && (
                <FruitMerge
                    setLoading={setLoading}
                    onClose={() => {
                        setFruitMergeOpened(false)
                    }}
                />
            )}
        </>
    )
}
