import QuizButton, { ButtonType } from '@components/common/QuizButton'
import { Box, Typography } from '@mui/material'
import { useUser } from '@providers/UserProvider'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../../App'
import { signOut } from 'firebase/auth'
import { firebaseAuth } from '@services/firebase'

export default function LogoutModal({
    setLogoutModalOpen,
}: {
    setLogoutModalOpen: (V: boolean) => void
}) {
    const navigate = useNavigate()

    const { setUser } = useUser()

    const handleLogout = () => {
        signOut(firebaseAuth).catch(console.error)
        setUser(null)
        navigate(Routes.SIGN_IN)
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                zIndex: 299,
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                userSelect: 'none',
                background: 'rgba(2, 2, 2, 0.50)',
            }}
        >
            <Box
                sx={{
                    marginTop: 'auto',
                    maxWidth: '410px',
                    background: '#FFF',
                    width: '100%',
                    padding: '20px',
                    margin: 'auto 20px',
                    borderRadius: '24px',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Avenir600',
                        fontSize: '20px',
                        lineHeight: 'normal',
                        color: '#020202',
                        textAlign: 'center',
                        marginBottom: '20px',
                        userSelect: 'none',
                    }}
                >
                    Are you sure you want to logout?
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        gap: '15px',
                    }}
                >
                    <QuizButton
                        onClick={handleLogout}
                        variant={ButtonType.MAIN}
                        text={'Yes'}
                    />
                    <QuizButton
                        onClick={() => {
                            setLogoutModalOpen(false)
                        }}
                        variant={ButtonType.TRANSPARENT}
                        text={'No'}
                    />
                </Box>
            </Box>
        </Box>
    )
}
