import { getHost } from '@services/hostUtils';

export const APP_CONSTANTS = {
  EMAIL: 'support@trackbeat.app',
  ADDRESS: '15 Cutter mill rd, Unit 556 Great Neck, NY, 11021',
  APP_NAME: 'Cognifi',
  APP_SHORT_NAME: 'Cognifi',
  IOS_DOWNLOAD_APP_LINK: 'https://app.appsflyer.com/id1348259279',
  ANDROID_DOWNLOAD_APP_LINK: 'https://apps.apple.com/pt/app/heart-rate-monitor/id1591295637?l=en-GB',
} as const;

export const APP_URLS = {
  APP_BASE_PATH: 'https://' + getHost() + '/cognifi/',
  DEV_APP_BASE_PATH: 'https://subs.galaxylineapp.com',
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
