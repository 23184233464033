import { Box, Typography } from '@mui/material'

function QuizHeader({
    stepNumber,
    stepsNumber,
    onBack,
    showSteps = true,
    noBackBtn,
    onLogoClick,
}: {
    stepsNumber: number
    stepNumber: number
    showSteps?: boolean
    noBackBtn?: boolean
    onBack: () => void
    onLogoClick?: () => void
}) {
    const fillPercentage = 100 * (stepNumber / stepsNumber)

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    maxWidth: '500px',
                    width: '100%',
                }}
            >
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    margin={'17px 0'}
                    padding={'0 20px'}
                >
                    <Box
                        onClick={onBack}
                        sx={{
                            visibility: noBackBtn ? 'hidden' : 'visible',
                            cursor: 'pointer',
                            transition: 'all ease 0.3s',
                            '&:hover': {
                                opacity: 0.6,
                            },
                        }}
                    >
                        <img
                            src={'/cognifi/svg/arrowBack.svg'}
                            alt="arrow back"
                        ></img>
                    </Box>

                    <img
                        onClick={onLogoClick}
                        width={'108px'}
                        src={'/cognifi/svg/logo.svg'}
                        alt="logo"
                    ></img>
                    <Typography
                        sx={{
                            fontFamily: 'Avenir600',
                            fontSize: '16px',
                            color: '#020202',
                            userSelect: 'none',
                            visibility: showSteps ? 'visible' : 'hidden',
                            '.blue': {
                                color: 'var(--primary-color)',
                            },
                        }}
                    >
                        <span className="blue">{stepNumber}</span>/{stepsNumber}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '460px',
                        margin: 'auto',
                        height: '4px',
                        background: `linear-gradient(to right,var(--primary-color) 0%,var(--primary-color) ${fillPercentage}%,rgba(2, 2, 2, 0.10) ${fillPercentage}%,rgba(2, 2, 2, 0.10) 100%)`,
                    }}
                ></Box>
            </Box>
        </Box>
    )
}

export default QuizHeader
