import {
    PageWrapper,
    PageTitle,
    PageDescription,
    BasePage,
} from '@components/WebApp/pages/common.style'

import * as S from './Tests.style'

// import { TimerContainer } from '@components/WebApp/components/Timer'
import { TestCard, ResultCard } from '@components/WebApp/pages/Tests/components'
import {
    TESTS,
    USER_RESULTS,
} from '@components/WebApp/pages/Tests/Tests.constants'

import BigBranchLeft from '@assets/svg/webapp/tests/big-branch-left.svg'
import BigBranchRight from '@assets/svg/webapp/tests/big-branch-right.svg'
import { useEffect, useState } from 'react'
import WebAppQuiz from './components/WebAppQuiz'

export default function Tests() {
    const [openedQuiz, setOpenedQuiz] = useState<string | null>()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <BasePage>
                <PageWrapper>
                    <PageTitle>IQ tests</PageTitle>

                    <PageDescription>
                        Here are the most relevant{' '}
                        <strong>certified tests</strong> from around the world
                        to assess your intellectual abilities.
                    </PageDescription>

                    <S.ImageWrapper>
                        <S.IntroImage
                            src="/cognifi/images/webapp/iq-tests/Intro_background_small.webp"
                            alt="intro"
                        />
                    </S.ImageWrapper>

                    {/* <TimerContainer targetUnixTime={1728988741} /> */}

                    <S.Cards>
                        {TESTS.map((card, index) => (
                            <TestCard
                                key={index}
                                {...card}
                                onButtonClick={() => {
                                    setOpenedQuiz(card.testName)
                                }}
                            />
                        ))}
                    </S.Cards>

                    <S.Inner>
                        <S.Experts>
                            <S.ExpertsGroup>
                                <BigBranchLeft />
                                <S.LargeTitle>
                                    {'CERTIFIED\nBY EXPERTS'}
                                </S.LargeTitle>
                                <BigBranchRight />
                            </S.ExpertsGroup>

                            <S.Description>
                                All our tests are certified by experts,
                                providing a thorough analysis of your responses
                                and personalized recommendations for
                                improvement.
                            </S.Description>
                        </S.Experts>

                        <PageTitle
                            className="center"
                            style={{ margin: '40px 0 0' }}
                        >
                            <b>Latest results</b> of our users who passed the IQ
                            test
                        </PageTitle>

                        <S.LastResults>
                            {USER_RESULTS.map((item, i) => (
                                <ResultCard {...item} key={i} />
                            ))}
                        </S.LastResults>

                        <PageTitle
                            className="center"
                            style={{ margin: '40px 0 0', width: '100%' }}
                        >
                            What’s the <b>average IQ?</b>
                        </PageTitle>

                        <S.Description style={{ margin: '10px 0 0' }}>
                            The average IQ score across all age groups is 100,
                            the midpoint of the "normal" range. Most scores
                            cluster around this, with fewer as they move further
                            from 100.
                        </S.Description>

                        <S.AverageIqImage src="/cognifi/images/webapp/iq-tests/average-iq.svg" />
                    </S.Inner>
                </PageWrapper>
            </BasePage>
            {openedQuiz && <WebAppQuiz openedQuiz={openedQuiz} setOpenedQuiz={setOpenedQuiz} />}
        </>
    )
}
