import React from 'react'
import { Box, Typography } from '@mui/material'
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks'
import QuizButton, { ButtonType } from '@components/common/QuizButton'

function QuizResults({
    testName,
    onBack,
}: {
    testName: string
    onBack: () => void
}) {
    const { userIqScore } = useQuizState()

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '100vh',
                backgroundImage:
                    'url(/cognifi/images/gradient_background.webp)',
                backgroundSize: '100% 400px',
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                backgroundColor: '#fff',
            }}
        >
            <Box
                sx={{
                    height: '56px',
                    borderBottom: '1px solid rgba(2, 2, 2, 0.10)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    width={'108px'}
                    src={'/cognifi/svg/logo.svg'}
                    alt="logo"
                ></img>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    margin: 'auto',
                    maxWidth: '460px',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        padding: '25px 20px',
                        flexDirection: 'column',
                        height: '100%',
                        '& .logo': {
                            margin: '30px auto 0',
                        },
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Avenir600',
                            fontSize: '24px',
                            lineHeight: 'normal',
                            color: '#020202',
                            textAlign: 'center',
                            userSelect: 'none',
                        }}
                    >
                        Your IQ based on the results of the "{testName}" test
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '20px 0',
                        }}
                    >
                        <img
                            width={42}
                            src="/cognifi/svg/results_leaf.svg"
                            alt="leaf"
                        ></img>
                        <Typography
                            sx={{
                                fontFamily: 'Avenir700',
                                fontSize: '72px',
                                lineHeight: 'normal',
                                color: '#020202',
                                textAlign: 'center',
                                userSelect: 'none',
                            }}
                        >
                            {userIqScore}
                        </Typography>
                        <img
                            width={42}
                            style={{ transform: 'scaleX(-1)' }}
                            src="/cognifi/svg/results_leaf.svg"
                            alt="leaf"
                        ></img>
                    </Box>
                    <Typography
                        sx={{
                            fontFamily: 'Avenir400',
                            fontSize: '16px',
                            lineHeight: 'normal',
                            color: 'rgba(2, 2, 2, 0.50)',
                            textAlign: 'center',
                            userSelect: 'none',
                            marginBottom: '20px',
                        }}
                    >
                        The average IQ score across all age groups is 100, the
                        midpoint of the "normal" range. Most scores cluster
                        around this, with fewer as they move further from 100.
                    </Typography>

                    <QuizButton
                        onClick={onBack}
                        variant={ButtonType.MAIN}
                        text={'Back to main page'}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default QuizResults
