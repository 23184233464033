import styled from 'styled-components'

export const ResultCard = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--grey-light-color);
    padding: 20px;
    border-radius: 20px;
    color: var(--black-color);
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
`

export const Name = styled.span`
    font-family: Avenir600;
    font-size: 16px;
`

export const Datetime = styled.span`
    font-family: Avenir400;
    font-size: 16px;
    color: var(--black-light-color);
`

export const Image = styled.img`
    min-width: 40px;
    width: 40px;
    height: 40px;
`

export const IqText = styled.span``

export const GradientGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    width: 100px;
    height: 80px;
    border-radius: 16px;
    overflow: hidden;
`
export const GradientInActive = styled.div<{ percent: number }>`
    position: absolute;
    width: 100%;
    height: calc(100% - ${(props) => props.percent}%);
    background: white;
    opacity: 0.6;
`

export const GradientActive = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #6639e6 0%, #af5fff 50%, #ff84d5 100%);
`

export const IqWrapper = styled.div<{ percent: number }>`
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: calc(${(props) => props.percent}% - 15px);
    left: 0;
    z-index: 2;

    &:before,
    &:after {
        content: '';
        display: block;
        width: 50%;
        background: white;
        height: 2px;
        top: 50%;
        transform: translateY(-50%);
    }

    &:before {
        left: 0;
    }

    &:before {
        right: 0;
    }
`

export const IqValue = styled.span`
    font-family: Avenir600;
    font-size: 16px;
    color: var(--black-color);
    padding: 6px 15px;
    background: white;
    border-radius: 20px;
    white-space: nowrap;
`
