import { styled } from 'styled-components'

export const ArticleCard = styled.div`
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid rgba(2, 2, 2, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    img {
        width: 100%;
    }
`
export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    font-family: Avenir600;
    font-size: 16px;
`

export const Tag = styled.div<{ $color: string }>`
    padding: 10px 15px 10px 10px;
    text-wrap: nowrap;
    font-family: Avenir600;
    font-size: 14px;
    color: #020202;
    display: flex;
    gap: 5px;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    background-color: ${(p) => p.$color}15;
    width: max-content;
`
export const ArticleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`
export const ArticleTitle = styled.div`
    font-family: Avenir600;
    font-size: 24px;
`

export const ArticleSectionTitle = styled.div`
    font-family: Avenir600;
    font-size: 20px;
`

export const ArticleSectionText = styled.div`
    font-family: Avenir400;
    font-size: 16px;
    margin-top: 15px;
`

export const ArticleTag = styled.div`
    padding: 10px 15px 10px 10px;
    text-wrap: nowrap;
    font-family: Avenir600;
    font-size: 14px;
    color: #020202;
    display: flex;
    gap: 5px;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    background-color: #fff;
    width: max-content;
`
export const BackButton = styled.div`
    cursor: pointer;
    color: #6639e6;
    font-family: Avenir500;
    font-size: 14px;
    display: flex;
    gap: 5px;
    align-items: center;
`
export const Image = styled.div`
    img {
        width: 100%;
        border-radius: 20px;
    }
`
export const DividerWrapper = styled.div`
    margin: 30px -20px;
    display: flex;
    align-items: center;
    gap: 20px;
    svg {
        min-width: 40px;
    }
`
export const DividerLine = styled.div`
    height: 1px;
    width: 100%;
    background: #e6e7eb;
`

export const SourcesWrapper = styled.div`
    margin: 0 -20px -20px;
    background: #f3f2f8;
    padding: 40px 20px;
`
export const ArticleSource = styled.div`
    margin-top: 10px;
    display: flex;
    gap: 10px;
`
export const ArticleSourceTitle = styled.div`
    font-family: Avenir600;
    font-size: 16px;
`

export const ArticleSourceLink = styled.a`
    margin-top: 5px;
    word-break: break-all;
    font-family: Avenir400;
    font-size: 12px;
    color: #6639e6;
    text-decoration: none;
`
export const ArticleSourceNumber = styled.div`
    border-radius: 50%;
    background: #fff;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Avenir600;
    font-size: 12px;
`
