import Analysis from '@components/Quiz/Steps/Analysis'
import Intro from '@components/Quiz/Steps/Intro'
import Question from '@components/Quiz/Steps/Question'
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks'
import { DEFAUL_QUIZ_DATA } from '@providers/QuizProvider/QuizProvider.types'
import { useEffect, useMemo, useState } from 'react'
import QuizQuitModal from './components/QuizQuitModal'
import Finish from '@components/Quiz/Steps/Finish'
import QuizResults from '@components/Quiz/Steps/QuizResults'
import {
    BRAIN_EVALUATION_QUESTIONS,
    COGNIFI_QUIZ_QUESTIONS,
} from '@components/Quiz/Quiz.constants'
import { preloadImage } from '@hooks/useImagePreloader'

const getCognifiOfficialStep = (
    stepNumber: number,
    handleLogoClick: () => void,
    handleQuitQuiz: () => void
) => {
    window.scrollTo(0, 0)

    switch (stepNumber) {
        case 1: {
            return <Intro />
        }
        case 2: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    noBackBtn
                    questionNumber={1}
                />
            )
        }
        case 3: {
            return <Question onLogoClick={handleLogoClick} questionNumber={2} />
        }
        case 4: {
            return <Question onLogoClick={handleLogoClick} questionNumber={3} />
        }
        case 5: {
            return <Question onLogoClick={handleLogoClick} questionNumber={4} />
        }
        case 6: {
            return <Question onLogoClick={handleLogoClick} questionNumber={5} />
        }
        case 7: {
            return <Question onLogoClick={handleLogoClick} questionNumber={6} />
        }
        case 8: {
            return <Question onLogoClick={handleLogoClick} questionNumber={7} />
        }
        case 9: {
            return <Question onLogoClick={handleLogoClick} questionNumber={8} />
        }
        case 10: {
            return <Question onLogoClick={handleLogoClick} questionNumber={9} />
        }
        case 11: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={10} />
            )
        }
        case 12: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={11} />
            )
        }
        case 13: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={12} />
            )
        }
        case 14: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={13} />
            )
        }
        case 15: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={14} />
            )
        }
        case 16: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={15} />
            )
        }
        case 17: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={16} />
            )
        }
        case 18: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={17} />
            )
        }
        case 19: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={18} />
            )
        }
        case 20: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={19} />
            )
        }
        case 21: {
            return (
                <Question onLogoClick={handleLogoClick} questionNumber={20} />
            )
        }

        case 22: {
            return <Finish />
        }
        case 23: {
            return <Analysis />
        }
        case 24: {
            return (
                <QuizResults
                    testName="Cognifi Official"
                    onBack={handleQuitQuiz}
                />
            )
        }
    }
}

const getBrainEvaluationStep = (
    stepNumber: number,
    handleLogoClick: () => void,
    handleQuitQuiz: () => void
) => {
    window.scrollTo(0, 0)

    switch (stepNumber) {
        case 1: {
            return <Intro />
        }
        case 2: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    noBackBtn
                    questionNumber={1}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 3: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={2}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 4: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={3}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 5: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={4}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 6: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={5}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 7: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={6}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 8: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={7}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 9: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={8}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 10: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={9}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 11: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={10}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 12: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={11}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 13: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={12}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 14: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={13}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 15: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={14}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 16: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={15}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 17: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={16}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 18: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={17}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 19: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={18}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 20: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={19}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }
        case 21: {
            return (
                <Question
                    onLogoClick={handleLogoClick}
                    questionNumber={20}
                    quizQuestions={BRAIN_EVALUATION_QUESTIONS}
                />
            )
        }

        case 22: {
            return <Finish />
        }
        case 23: {
            return <Analysis />
        }
        case 24: {
            return (
                <QuizResults
                    testName="Brain Power Evaluation"
                    onBack={handleQuitQuiz}
                />
            )
        }
    }
}

export default function WebAppQuiz({
    openedQuiz,
    setOpenedQuiz,
}: {
    openedQuiz: string
    setOpenedQuiz: (v: string | null) => void
}) {
    const [isQuitModalOpen, setQuitModalOpen] = useState(false)
    const { step, setQuizData, setStep, setUserIqScore } = useQuizState()

    useEffect(() => {
        setQuizData(DEFAUL_QUIZ_DATA)
    }, [setQuizData])

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    const handleLogoClick = () => {
        setQuitModalOpen(true)
    }

    const handleQuitQuiz = () => {
        setStep(1)
        setOpenedQuiz(null)
        setQuitModalOpen(false)
        setUserIqScore(null)
    }

    const prelodImages = async (imagePaths: Array<string>) => {
        const imagesPromiseList: Promise<any>[] = []

        for (const i of imagePaths) {
            imagesPromiseList.push(preloadImage(i))
        }

        const promiseSettledResult = await Promise.allSettled(imagesPromiseList)

        const rejected = promiseSettledResult.find(
            (promise) => promise.status === 'rejected'
        )
        if (rejected) {
            console.error('Some quiz images was not loaded: ', rejected)
        } else {
            console.error('Quiz Images loaded')
        }
    }

    const steps = useMemo(() => {
        switch (openedQuiz) {
            case 'brain_evaluation': {
                return getBrainEvaluationStep(
                    step,
                    handleLogoClick,
                    handleQuitQuiz
                )
            }
            default: {
                return getCognifiOfficialStep(
                    step,
                    handleLogoClick,
                    handleQuitQuiz
                )
            }
        }
    }, [step, openedQuiz])

    useEffect(() => {
        switch (openedQuiz) {
            case 'brain_evaluation': {
                const quizImages: Array<string> = []
                BRAIN_EVALUATION_QUESTIONS.forEach((el) => {
                    quizImages.push(el.image)
                    quizImages.push(...el.answers)
                })
                prelodImages(quizImages)
                return
            }
            default: {
                const quizImages: Array<string> = []
                COGNIFI_QUIZ_QUESTIONS.forEach((el) => {
                    quizImages.push(el.image)
                    quizImages.push(...el.answers)
                })
                prelodImages(quizImages)
                return
            }
        }
    }, [openedQuiz])

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 99,
                background: 'white',
                overflow: 'auto',
            }}
        >
            {steps}
            {isQuitModalOpen && (
                <QuizQuitModal
                    handleQuitQuiz={handleQuitQuiz}
                    setQuitModalOpen={setQuitModalOpen}
                />
            )}
        </div>
    )
}
