import { styled } from 'styled-components'

export const TagsWrapper = styled.div`
    margin: 10px -20px 20px;
    padding: 0 20px;
    display: flex;
    gap: 10px;
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`
export const Tag = styled.div<{ $color: string; $isSelected: boolean }>`
    cursor: pointer;
    padding: 10px 15px 10px 10px;
    text-wrap: nowrap;
    font-family: Avenir600;
    font-size: 14px;
    color: #020202;
    display: flex;
    gap: 5px;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px 0px rgba(69, 50, 120, 0.05);
    border: 2px solid transparent;
    ${(p) =>
        p.$isSelected &&
        `
        border: 2px solid ${p.$color};
    `}
`

export const ArticlesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
