import * as S from './Article.style'
import { ArticleType, CATHEGORIES } from '../Articles.constants'

export default function ArticleCard({
    article,
    onClick,
}: {
    article: ArticleType & { cathegory: string }
    onClick: () => void
}) {
    const cathegoryData = CATHEGORIES.find(
        (tag) => tag.cathegory === article.cathegory
    )

    return (
        <S.ArticleCard onClick={onClick}>
            <img src={article.image_src} alt="article"></img>
            <S.DescriptionWrapper>
                {cathegoryData && (
                    <S.Tag $color={cathegoryData.color}>
                        {cathegoryData.icon}
                        {cathegoryData.title}
                    </S.Tag>
                )}
                {article.title}
            </S.DescriptionWrapper>
        </S.ArticleCard>
    )
}
