export type QuizQuestions = Array<{
    image: string
    answers: Array<string>
}>

export const COGNIFI_QUIZ_QUESTIONS: QuizQuestions = [
    {
        image: '/cognifi/images/quiz/question_question_1.webp',
        answers: [
            '/cognifi/images/quiz/answer_1_1.webp',
            '/cognifi/images/quiz/answer_1_2.webp',
            '/cognifi/images/quiz/answer_1_3_correct_.webp',
            '/cognifi/images/quiz/answer_1_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/question_question_2.webp',
        answers: [
            '/cognifi/images/quiz/answer_2_1.webp',
            '/cognifi/images/quiz/answer_2_2.webp',
            '/cognifi/images/quiz/answer_2_3_correct_.webp',
            '/cognifi/images/quiz/answer_2_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_3.webp',
        answers: [
            '/cognifi/images/quiz/answer_3_1.webp',
            '/cognifi/images/quiz/answer_3_2_correct_.webp',
            '/cognifi/images/quiz/answer_3_3.webp',
            '/cognifi/images/quiz/answer_3_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_4.webp',
        answers: [
            '/cognifi/images/quiz/answer_4_1_correct_.webp',
            '/cognifi/images/quiz/answer_4_2.webp',
            '/cognifi/images/quiz/answer_4_3.webp',
            '/cognifi/images/quiz/answer_4_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_5.webp',
        answers: [
            '/cognifi/images/quiz/answer_5_1.webp',
            '/cognifi/images/quiz/answer_5_2.webp',
            '/cognifi/images/quiz/answer_5_3.webp',
            '/cognifi/images/quiz/answer_5_4_correct_.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_6.webp',
        answers: [
            '/cognifi/images/quiz/answer_6_1_correct_.webp',
            '/cognifi/images/quiz/answer_6_2.webp',
            '/cognifi/images/quiz/answer_6_3.webp',
            '/cognifi/images/quiz/answer_6_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_7.webp',
        answers: [
            '/cognifi/images/quiz/answer_7_1.webp',
            '/cognifi/images/quiz/answer_7_2.webp',
            '/cognifi/images/quiz/answer_7_3_correct_.webp',
            '/cognifi/images/quiz/answer_7_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_8.webp',
        answers: [
            '/cognifi/images/quiz/answer_8_1.webp',
            '/cognifi/images/quiz/answer_8_2.webp',
            '/cognifi/images/quiz/answer_8_3.webp',
            '/cognifi/images/quiz/answer_8_4_correct_.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_9.webp',
        answers: [
            '/cognifi/images/quiz/answer_9_1.webp',
            '/cognifi/images/quiz/answer_9_2.webp',
            '/cognifi/images/quiz/answer_9_3_correct_.webp',
            '/cognifi/images/quiz/answer_9_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_10.webp',
        answers: [
            '/cognifi/images/quiz/answer_10_1.webp',
            '/cognifi/images/quiz/answer_10_2_correct_.webp',
            '/cognifi/images/quiz/answer_10_3.webp',
            '/cognifi/images/quiz/answer_10_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_11.webp',
        answers: [
            '/cognifi/images/quiz/answer_11_1.webp',
            '/cognifi/images/quiz/answer_11_2.webp',
            '/cognifi/images/quiz/answer_11_3.webp',
            '/cognifi/images/quiz/answer_11_4_correct_.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_12.webp',
        answers: [
            '/cognifi/images/quiz/answer_12_1.webp',
            '/cognifi/images/quiz/answer_12_2_correct_.webp',
            '/cognifi/images/quiz/answer_12_3.webp',
            '/cognifi/images/quiz/answer_12_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/question_question_13.webp',
        answers: [
            '/cognifi/images/quiz/answer_13_1.webp',
            '/cognifi/images/quiz/answer_13_2_correct_.webp',
            '/cognifi/images/quiz/answer_13_3.webp',
            '/cognifi/images/quiz/answer_13_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/question_question_14.webp',
        answers: [
            '/cognifi/images/quiz/answer_14_1.webp',
            '/cognifi/images/quiz/answer_14_2.webp',
            '/cognifi/images/quiz/answer_14_3.webp',
            '/cognifi/images/quiz/answer_14_4_correct_.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/question_question_15.webp',
        answers: [
            '/cognifi/images/quiz/answer_15_1.webp',
            '/cognifi/images/quiz/answer_15_2_correct_.webp',
            '/cognifi/images/quiz/answer_15_3.webp',
            '/cognifi/images/quiz/answer_15_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/question_question_16.webp',
        answers: [
            '/cognifi/images/quiz/answer_16_1.webp',
            '/cognifi/images/quiz/answer_16_2.webp',
            '/cognifi/images/quiz/answer_16_3.webp',
            '/cognifi/images/quiz/answer_16_4_correct_.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/question_question_17.webp',
        answers: [
            '/cognifi/images/quiz/answer_17_1.webp',
            '/cognifi/images/quiz/answer_17_2.webp',
            '/cognifi/images/quiz/answer_17_3_correct_.webp',
            '/cognifi/images/quiz/answer_17_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/question_question_18.webp',
        answers: [
            '/cognifi/images/quiz/answer_18_1.webp',
            '/cognifi/images/quiz/answer_18_2_correct_.webp',
            '/cognifi/images/quiz/answer_18_3.webp',
            '/cognifi/images/quiz/answer_18_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/question_question_19.webp',
        answers: [
            '/cognifi/images/quiz/answer_19_1.webp',
            '/cognifi/images/quiz/answer_19_2.webp',
            '/cognifi/images/quiz/answer_19_3.webp',
            '/cognifi/images/quiz/answer_19_4_correct_.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/question_question_20.webp',
        answers: [
            '/cognifi/images/quiz/answer_20_1_correct_.webp',
            '/cognifi/images/quiz/answer_20_2.webp',
            '/cognifi/images/quiz/answer_20_3.webp',
            '/cognifi/images/quiz/answer_20_4.webp',
        ],
    },
]

export const BRAIN_EVALUATION_QUESTIONS: QuizQuestions = [
    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_1.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_1_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_1_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_1_3_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_1_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_2.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_2_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_2_2_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_2_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_2_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_3.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_3_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_3_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_3_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_3_4_correct_.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_4.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_4_1_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_4_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_4_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_4_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_5.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_5_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_5_2_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_5_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_5_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_6.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_6_1_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_6_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_6_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_6_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_7.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_7_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_7_2_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_7_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_7_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_8.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_8_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_8_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_8_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_8_4_correct_.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_9.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_9_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_9_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_9_3_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_9_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_10.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_10_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_10_2_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_10_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_10_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_11.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_11_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_11_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_11_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_11_4_correct_.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_12.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_12_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_12_2_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_12_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_12_4.webp',
        ],
    },

    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_13.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_13_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_13_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_13_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_13_4_correct_.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_14.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_14_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_14_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_14_3_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_14_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_15.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_15_1_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_15_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_15_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_15_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_16.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_16_1_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_16_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_16_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_16_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_17.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_17_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_17_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_17_3_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_17_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_18.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_18_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_18_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_18_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_18_4_correct_.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_19.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_19_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_19_2_correct_.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_19_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_19_4.webp',
        ],
    },
    {
        image: '/cognifi/images/quiz/brain_evaluation/test_2_question_question_20.webp',
        answers: [
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_20_1.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_20_2.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_20_3.webp',
            '/cognifi/images/quiz/brain_evaluation/test_2_answer_20_4_correct_.webp',
        ],
    },
]
