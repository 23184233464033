import styled from 'styled-components'
import { PageWrapper } from '@components/WebApp/pages/common.style'

export const MainItems = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 2;
`

export const MainItemButton = styled.button`
    display: flex;
    align-items: center;
    border: 0;
    padding: 20px 10px 20px 20px;
    outline: none;
    border-radius: 20px;
    box-shadow: 0 5px 20px 0 rgba(69, 50, 120, 0.05);
    background: white;
    cursor: pointer;

    svg:last-child {
        margin-left: auto;
    }
`

export const MainItemGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    margin-left: 20px;
`

export const MainItemImage = styled.img`
    width: 67px;
    height: 66px;
    pointer-events: none;
    user-select: none;
`

export const MainItemButtonTitle = styled.span`
    font-family: Avenir600;
    font-size: 16px;
    text-align: left;
    color: #020202;
`

export const MainItemButtonText = styled.span`
    max-width: 183px;
    font-family: Avenir400;
    font-size: 14px;
    color: var(--black-light-color);
    text-align: left;
`

export const Items = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--black-10-color);
    border-radius: 16px;
    color: var(--black-color);
`

export const ItemButton = styled.button`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 10px 15px 20px;
    border: 0;
    border-bottom: 1px solid var(--black-10-color);
    outline: none;
    background: none;
    cursor: pointer;

    &:last-child {
        border-bottom: 0;
    }

    svg {
        pointer-events: none;
        user-select: none;
    }

    .arrow {
        margin-left: auto;
    }

    svg:last-child {
        margin-left: auto;
    }
`

export const LogoutButton = styled.button`
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: rgba(229, 62, 62, 0.1);
    color: rgba(229, 62, 62, 1);
    padding: 15px 10px 15px 20px;
    border-radius: 12px;
    font-family: Avenir600;
    font-size: 16px;

    border: 0;
    outline: none;

    svg {
        display: block;
    }
`

export const ItemText = styled.span`
    font-family: Avenir600;
    font-size: 16px;
    color: #020202;
`
