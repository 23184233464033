import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { getPriceWithDiscount, redirectToAppLogin } from '@utils/payment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Routes } from '../../../App';
import { trackEvent } from '@api/requests';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';

function PackWrapper({
  image,
  isSelected,
  children,
  onClick,
}: {
  image: string;
  isSelected: boolean;
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius: '16px',
        padding: '10px 20px 10px 10px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        outline: isSelected ? '2px solid #6639E6' : '1px solid rgba(2, 2, 2, 0.10)',
      }}
    >
      <img width={60} src={image} alt="pack preview"></img>
      <Box
        sx={{
          width: '100%',
        }}
      >
        {children}
      </Box>
      {isSelected ? (
        <img src={'svg/subs_checked_pack.svg'} alt="pack preview"></img>
      ) : (
        <Box
          sx={{
            minWidth: '24px',
            height: '24px',
            borderRadius: '50%',
            border: '1px solid rgba(2, 2, 2, 0.10)',
          }}
        ></Box>
      )}
    </Box>
  );
}
type OfferTypes = 'ultimate_pack' | 'early_access' | 'analysis_report' | 'skip_offer';

function AddPacks({
  setStepNumber,
  setPdfReportPurchased,
}: {
  setPdfReportPurchased: React.Dispatch<React.SetStateAction<boolean>>;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { isAdditionalPaymentLoading, additionalPaymentError, handleAdditionalOfferAdd } = usePaymentState();
  const getAvailableOption = () => {
    const params = new URLSearchParams(window.location.search);
    switch (true) {
      case params.get('ult_pack') !== null: {
        return 'ultimate_pack';
      }
      case params.get('early_access') !== null: {
        return 'early_access';
      }
      case params.get('analysis_report') !== null: {
        return 'analysis_report';
      }
      default: {
        return 'skip_offer';
      }
    }
  };

  const [selectedOffer, setSelectedOffer] = useState<OfferTypes>(getAvailableOption());
  const { ultPack, earlyAccessPlan, analysisReportPlan, showAdditionalOffersPrice } = usePaymentConfig();
  const { userLocation } = useQuizState();

  useEffect(() => {
      if (getAvailableOption() === 'skip_offer') {
          redirectToAppLogin()
      }
  }, [])

  const handleGetTheOffer = useCallback(() => {
    if (isAdditionalPaymentLoading) return;

    switch (selectedOffer) {
      case 'ultimate_pack': {
        if (ultPack) {
          handleAdditionalOfferAdd(ultPack, () => {
            redirectToAppLogin()
          });
        }
        return;
      }
      case 'early_access': {
        if (earlyAccessPlan) {
          handleAdditionalOfferAdd(earlyAccessPlan, () => {
            setStepNumber((prev) => prev + 2);
          });
        }
        return;
      }
      case 'analysis_report': {
        if (analysisReportPlan) {
          handleAdditionalOfferAdd(analysisReportPlan, () => {
            setPdfReportPurchased(true);
            setStepNumber((prev) => prev + 1);
          });
        }
        return;
      }
      case 'skip_offer': {
        setStepNumber((prev) => prev + 1);
        trackEvent(`Skip packs offers click`, userLocation).catch(console.error);
        return;
      }
    }
  }, [selectedOffer, ultPack, earlyAccessPlan, analysisReportPlan]);

  const planCost = useMemo(() => {
    switch (selectedOffer) {
      case 'ultimate_pack': {
        return ultPack?.sum;
      }
      case 'early_access': {
        return earlyAccessPlan?.sum;
      }
      case 'analysis_report': {
        return analysisReportPlan?.sum;
      }
      case 'skip_offer': {
        return 0;
      }
    }
  }, [selectedOffer, ultPack, earlyAccessPlan, analysisReportPlan]);

  return (
    <Box
      sx={{
        maxWidth: '460px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        overflow: 'auto',
        margin: 'auto',
        padding: '0 20px',
      }}
    >
      <Typography
        sx={{
          margin: '20px 0 0',
          fontFamily: 'Avenir600',
          fontSize: '24px',
          lineHeight: '32px',
          color: '#020202',
          textAlign: 'center',
        }}
      >
        Exclusive sign-up offer
      </Typography>

      <Box
        sx={{
          margin: '20px 0',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '10px',
          '& .title': {
            fontFamily: 'Avenir600',
            fontSize: '16px',
            color: '#020202',
          },
          '& .subTitle': {
            fontFamily: 'Avenir400',
            fontSize: '10px',
            lineHeight: 'normal',
            color: '#020202',
            marginBottom: '5px',
          },
          '& .price_wrapper': {
            display: showAdditionalOffersPrice ? 'flex' : 'none',
            gap: '5px',
            alignItems: 'center',
          },
          '& .prev_price': {
            fontFamily: 'Avenir400',
            fontSize: '14px',
            color: '#A9A7AF',
            lineHeight: 'normal',
            textDecoration: 'line-through',
            textDecorationColor: '#E53E3E',
          },
          '& .curr_price': {
            fontFamily: 'Avenir600',
            fontSize: '14px',
            color: '#E53E3E',
            lineHeight: 'normal',
          },
          '& .discount': {
            fontFamily: 'Avenir600',
            fontSize: '10px',
            color: '#E53E3E',
            lineHeight: 'normal',
            display: 'flex',
            background: 'rgba(229, 62, 62, 0.10)',
            padding: '3px 8px',
            borderRadius: '20px',
          },
          '& .skipSubTitle': {
            fontFamily: 'Avenir400',
            fontSize: '14px',
            lineHeight: 'normal',
            color: '#A9A7AF',
          },
        }}
      >
        {ultPack && (
          <PackWrapper
            image="images/subscription/image_1.webp"
            isSelected={selectedOffer === 'ultimate_pack'}
            onClick={() => {
              setSelectedOffer('ultimate_pack');
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography className="title">Ultimate pack</Typography>
              <Typography className="subTitle">
                4 in 1: Early access to new exclusive games, extended analysis report, personalized IQ certificate, and
                offline access!
              </Typography>
              <Box className="price_wrapper">
                <span className="prev_price">
                  ${ultPack && getPriceWithDiscount(ultPack.sum, ultPack.discount_percent)}
                </span>
                <img src="svg/pack_arrow.svg" alt="arrow"></img>
                <span className="curr_price">${ultPack.sum}</span>
                <span className="discount">{ultPack.discount_percent}% OFF</span>
              </Box>
            </Box>
          </PackWrapper>
        )}

        {earlyAccessPlan && (
          <PackWrapper
            image="images/subscription/image_2.webp"
            isSelected={selectedOffer === 'early_access'}
            onClick={() => {
              setSelectedOffer('early_access');
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography className="title">Early access to new games</Typography>
              <Box className="price_wrapper">
                <span className="prev_price">
                  ${earlyAccessPlan && getPriceWithDiscount(earlyAccessPlan.sum, earlyAccessPlan.discount_percent)}
                </span>
                <img src="svg/pack_arrow.svg" alt="arrow"></img>
                <span className="curr_price">${earlyAccessPlan.sum}</span>
                <span className="discount">{earlyAccessPlan.discount_percent}% OFF</span>
              </Box>
            </Box>
          </PackWrapper>
        )}

        {analysisReportPlan && (
          <PackWrapper
            image="images/subscription/image_3.webp"
            isSelected={selectedOffer === 'analysis_report'}
            onClick={() => {
              setSelectedOffer('analysis_report');
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography className="title">Extended analysis report</Typography>
              <Box className="price_wrapper">
                <span className="prev_price">
                  $
                  {analysisReportPlan &&
                    getPriceWithDiscount(analysisReportPlan.sum, analysisReportPlan.discount_percent)}
                </span>
                <img src="svg/pack_arrow.svg" alt="arrow"></img>
                <span className="curr_price">${analysisReportPlan.sum}</span>
                <span className="discount">{analysisReportPlan.discount_percent}% OFF</span>
              </Box>
            </Box>
          </PackWrapper>
        )}

        <PackWrapper
          image="images/subscription/image_4.webp"
          isSelected={selectedOffer === 'skip_offer'}
          onClick={() => {
            setSelectedOffer('skip_offer');
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography className="title">Skip offer</Typography>
            <Typography className="skipSubTitle">Missing all opportunities</Typography>
          </Box>
        </PackWrapper>
      </Box>
      {additionalPaymentError && (
        <Typography sx={{ margin: '10px 20px', color: '#E03045', textAlign:'center' }}>{additionalPaymentError}</Typography>
      )}
      <QuizButton onClick={handleGetTheOffer} variant={ButtonType.MAIN} text="">
        {isAdditionalPaymentLoading ? (
          <CircularProgress size={20} style={{ color: 'white' }}></CircularProgress>
        ) : (
          <>{selectedOffer === 'skip_offer' ? 'Letting go' : 'Get the offer'}</>
        )}
      </QuizButton>
      <Typography
        sx={{
          fontFamily: 'Avenir400',
          fontSize: '12px',
          lineHeight: 'normal',
          color: '#A9A7AF',
          textAlign: 'center',
          margin: '15px 0 30px',
          '& .link': {
            color: '#A9A7AF',
            textDecorationColor: '#A9A7AF',
          },
        }}
      >
        {selectedOffer !== 'skip_offer' && (
          <>
            By clicking the button, I agree to enroll in a one-time subscription at the cost of ${planCost}. I have
            thoroughly reviewed and accepted the{' '}
            <Link target="_blank" href={Routes.TERMS} className="link">
              Terms and Conditions.
            </Link>
          </>
        )}
      </Typography>
    </Box>
  );
}

export default AddPacks;
