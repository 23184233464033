import styled, { css } from 'styled-components'
import Logo from '@assets/svg/webapp/logo.svg'
import useScrollPosition from '@hooks/useScrollPosition'
import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Routes } from '../../../../App'

const Wrapper = styled.header<{ $isScrolled: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-bottom: 1px solid var(--black-10-color);
    transition: all 0.3s ease;
    position: sticky;
    top: 0;

    ${(props) =>
        props.$isScrolled &&
        css`
            background: #ffffffb2;
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(20px);
            z-index: 3;
        `}
`

export default function Header() {
    const isScrolled = useScrollPosition(20)
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const showBackBtn = pathname.includes(Routes.WEBAPP_MANAGE_SUBSCRIPTIONS)

    return (
        <Wrapper $isScrolled={isScrolled}>
            <Logo />
            <Box
                onClick={()=>{navigate(-1)}}
                sx={{
                    visibility: showBackBtn ? 'visible' : 'hidden',
                    cursor: 'pointer',
                    position: 'absolute',
                    left: '20px',
                    transition: 'all ease 0.3s',
                    '&:hover': {
                        opacity: 0.6,
                    },
                }}
            >
                <img src={'/cognifi/svg/arrowBack.svg'} alt="arrow back"></img>
            </Box>
        </Wrapper>
    )
}
