import { styled } from 'styled-components'

export const Image = styled.img`
    width: 100%;
`

export const ImageWrapper = styled.div`
    margin: 20px -20px 20px;
`

export const Games = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const LoadingWrapper = styled.div`
    background-image: url(/cognifi/images/gradient_background_fullscreen.webp);
    background-size: cover;
    background-color: #fff;
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const LoadingDescription = styled.span`
    margin-top: 10px;
    font-family: Avenir400;
    font-size: 16px;
    color: var(--black-50-color);
    text-align: center;
`

export const LoadingTitle = styled.span`
    display: inline-block;
    font-family: Avenir600;
    font-size: 24px;
    margin-top: 20px;
`
