import React, { ReactNode } from 'react'
import * as S from './TestCard.style'
import PlayIcon from '@assets/svg/webapp/tests/play-button.svg'
import PeopleIcon from '@assets/svg/webapp/tests/people.svg'
import BranchLeft from '@assets/svg/webapp/tests/branch-left.svg'
import BranchRight from '@assets/svg/webapp/tests/branch-right.svg'
import GameDisabledIcon from '@assets/svg/webapp/games/game_disabled_icon.svg'

export interface TestCardProps {
    title: string
    description: string
    countryIcon: ReactNode
    verificationLabel: string
    verifiedBy: string
    passedCount: number
    buttonText: string
    img: string
    isComingSoon: boolean
    isVerifiedByCognifi: boolean
    testName: string
    onButtonClick: () => void
}

const TestCard: React.FC<TestCardProps> = ({
    title,
    description,
    countryIcon,
    verificationLabel,
    verifiedBy,
    passedCount,
    buttonText,
    onButtonClick,
    isComingSoon,
    isVerifiedByCognifi,
    img,
}) => {
    return (
        <S.Card>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
            <S.Certification>
                {countryIcon} {verificationLabel}
            </S.Certification>
            <S.Verification>
                {isVerifiedByCognifi && (
                    <S.VerifiedBy>
                        <BranchLeft />
                        <S.VerifiedByText
                            dangerouslySetInnerHTML={{ __html: verifiedBy }}
                        />
                        <BranchRight />
                    </S.VerifiedBy>
                )}

                {!isComingSoon && (
                    <S.PassedCount>
                        <PeopleIcon />
                        <strong>
                            {passedCount.toLocaleString('en-US')}
                        </strong>{' '}
                        passed
                    </S.PassedCount>
                )}
            </S.Verification>

            {isComingSoon ? (
                <S.DisabledButton>
                    <GameDisabledIcon />
                    Coming soon
                </S.DisabledButton>
            ) : (
                <S.Button onClick={onButtonClick}>
                    <PlayIcon />
                    {buttonText}
                </S.Button>
            )}
            <S.ImageWrapper>
                <S.Image src={img} />
            </S.ImageWrapper>
        </S.Card>
    )
}

export default TestCard
