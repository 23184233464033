import { Box, Button, useMediaQuery } from '@mui/material'
import { isOpenedInTelegram } from '@utils/browser'

export enum ButtonType {
    MAIN = 'MAIN',
    WHITE = 'white',
    SKIP_TRIAL = 'SKIP_TRIAL',
    RESULTS = 'RESULTS',
    MAIN_FIXED = 'MAIN_FIXED',
    CHECKBOX = 'checkbox',
    CHECKBOX_SELECTED = 'checkbox_selected',
    APPLE = 'apple',
    TRANSPARENT = 'TRANSPARENT',
    CANCEL_SUBS = 'CANCEL_SUBS',
}

function QuizButton({
    text,
    variant,
    onClick,
    disabled,
    height,
    padding,
    children,
    type,
    className,
    withImage,
    imageWidth,
    imageGap,
    bgColor,
}: {
    text: string | JSX.Element
    variant: ButtonType
    onClick: () => void
    disabled?: boolean
    height?: string
    padding?: string
    children?: JSX.Element
    type?: 'button' | 'submit' | 'reset'
    className?: string
    withImage?: string
    imageWidth?: number
    imageGap?: boolean
    bgColor?: string
}) {
    const isMobile = useMediaQuery('(max-width:600px)')

    const getOverrideButtonStyles = () => {
        switch (variant) {
            case ButtonType.WHITE: {
                return {
                    backgroundColor: '#F3F2F8',
                    fontFamily: 'Avenir600',
                    fontSize: '14px',
                    height: '52px',
                    color: '#020202',
                    '&:hover': {
                        opacity: isMobile ? 1 : 0.8,
                    },
                }
            }
            case ButtonType.SKIP_TRIAL: {
                return {
                    backgroundColor: '#6639E6',
                    fontFamily: 'Avenir600',
                    fontSize: '14px',
                    height: '52px',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#6639E6',
                        opacity: isMobile ? 1 : 0.8,
                    },
                }
            }
            case ButtonType.RESULTS: {
                return {
                    backgroundColor: bgColor ?? '#6639E6',
                    fontFamily: 'Avenir600',
                    fontSize: '16px',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: bgColor ?? '#6639E6',
                        opacity: isMobile ? 1 : 0.8,
                    },
                }
            }
            case ButtonType.MAIN: {
                return {
                    backgroundColor: 'var(--primary-color)',
                    color: '#fff',
                    fontFamily: 'Avenir600',
                    '&:hover': {
                        backgroundColor: 'var(--primary-color)',
                        opacity: isMobile ? 1 : 0.8,
                    },
                    '&:disabled': {
                        backgroundColor: '#CBCCD1',
                        color: '#FFF',
                    },
                }
            }
            case ButtonType.MAIN_FIXED: {
                return {
                    backgroundColor: 'var(--primary-color)',
                    color: '#fff',
                    fontFamily: 'Avenir600',
                    position: 'fixed',
                    borderRadius: 0,
                    bottom: isOpenedInTelegram() ? '80px' : 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    '&:hover': {
                        backgroundColor: 'var(--primary-color)',
                        opacity: isMobile ? 1 : 0.8,
                    },
                    '&:disabled': {
                        backgroundColor: '#CBCCD1',
                        color: '#FFF',
                    },
                }
            }
            case ButtonType.CHECKBOX: {
                return {
                    backgroundColor: '#FFF',
                    color: '#020202',
                    justifyContent: 'space-between',
                    '&:hover': {
                        backgroundColor: isMobile ? '#FFF' : '#FFF',
                    },
                }
            }
            case ButtonType.CHECKBOX_SELECTED: {
                return {
                    backgroundColor: '#FFF',
                    color: '#020202',
                    justifyContent: 'space-between',
                    fontFamily: 'Avenir500',
                    outline: '2px solid var(--primary-color)',

                    '&:hover': {
                        backgroundColor: '#FFF',
                        opacity: isMobile ? 1 : 0.8,
                    },
                    '&:disabled': {
                        backgroundColor: '#FFF',
                        color: '#020202',
                    },
                }
            }
            case ButtonType.APPLE: {
                return {
                    backgroundColor: '#020202',
                    color: '#FFF',
                    '&:hover': {
                        backgroundColor: '#020202',
                        opacity: isMobile ? 1 : 0.8,
                    },
                    '&:disabled': {
                        backgroundColor: 'rgba(40, 38, 36, 0.8)',
                        color: '#FFF',
                    },
                }
            }
            case ButtonType.TRANSPARENT: {
                return {
                    backgroundColor: 'transparent',
                    color: '#020202',
                    fontFamily: 'Avenir600',
                    outline: '1px solid rgba(2, 2, 2, 0.10)',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }
            }
            case ButtonType.CANCEL_SUBS: {
                return {
                    backgroundColor: '#E53E3E10',
                    color: '#E53E3E',
                    fontFamily: 'Avenir600',
                    outline: 'none',
                    '&:hover': {
                        backgroundColor: '#E53E3E10',
                    },
                }
            }
        }
    }

    const getEndIcon = () => {
        switch (variant) {
            case ButtonType.CHECKBOX: {
                return (
                    <Box
                        sx={{
                            border: '1px solid #E6E7EB',
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                        }}
                    ></Box>
                )
            }
            case ButtonType.CHECKBOX_SELECTED: {
                return (
                    <img src={'svg/checked_icon_green.svg'} alt="checked"></img>
                )
            }
            default: {
                return undefined
            }
        }
    }

    return (
        <Button
            className={className}
            disabled={disabled}
            endIcon={getEndIcon()}
            fullWidth
            type={type}
            sx={{
                position: 'relative',
                overflow: 'hidden',
                fontFamily: 'Avenir500',
                fontSize: '16px',
                textTransform: 'none',
                height: height ?? '60px',
                borderRadius: '12px',
                transition: 'all 0.3s ease',
                padding: padding ?? '0 20px',
                justifyContent: 'center',
                outline: 'none',
                zIndex: 2,
                ...getOverrideButtonStyles(),
            }}
            onClick={onClick}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'left',
                    lineHeight: 'normal',
                    gap: imageGap ? '10px' : undefined,
                }}
            >
                {withImage && (
                    <img
                        width={imageWidth ?? 80}
                        src={withImage}
                        alt="answer"
                    ></img>
                )}
                {text}
            </Box>
            {children}
        </Button>
    )
}

export default QuizButton
