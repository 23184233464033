import { Box, Typography } from '@mui/material';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Intro() {
  const { setStep, setQuizData } = useQuizState();

  return (
    <QuizStepBackgroundWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          padding: '0 20px',
        }}
      >
        <Box
          sx={{
            borderRadius: '24px',
            backgroundColor: '#FFF',
            boxShadow: '0px 5px 20px 0px rgba(69, 50, 120, 0.05)',
            padding: '30px',
          }}
        >
          <img src="/cognifi/svg/intro_sign.svg" alt="sign"></img>
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '28px',
              color: '#020202',
              userSelect: 'none',
              margin: '15px 0',
              span: {
                color: 'var(--primary-color)',
              },
            }}
          >
            Get ready to start the <span>IQ test!</span>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              marginBottom: '20px',
              '& div': {
                display: 'flex',
                gap: '10px',

                '& p': {
                  fontFamily: 'Avenir400',
                  fontSize: '16px',
                  color: '#020202',
                  lineHeight: 'normal',
                  span: {
                    fontFamily: 'Avenir600',
                    color: 'var(--primary-color)',
                  },
                },
              },
            }}
          >
            <Box>
              <img src="/cognifi/svg/into_checkmark.svg" alt="sign"></img>
              <Typography>
                You will have to answer <span>20 questions</span> of varying difficulty
              </Typography>
            </Box>
            <Box>
              <img src="/cognifi/svg/into_checkmark.svg" alt="sign"></img>
              <Typography>
                Choose the correct answer from the <span>4 options given</span>
              </Typography>
            </Box>
            <Box>
              <img src="/cognifi/svg/into_checkmark.svg" alt="sign"></img>
              <Typography>You can skip the question and return back later</Typography>
            </Box>
          </Box>

          <QuizButton
            onClick={() => {
              setStep((prev) => prev + 1);
              setQuizData((prev) => ({ ...prev, startDate: Date.now() }));
            }}
            variant={ButtonType.MAIN}
            text={'Start Certified Test'}
          />
        </Box>
      </Box>
    </QuizStepBackgroundWrapper>
  );
}

export default Intro;
