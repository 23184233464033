import { useEffect, useMemo, useState } from 'react';
import SignInHeader from './components/SignInHeader';
import { Box } from '@mui/material';
import AddPacks from './IQSteps/AddPacks';
import Access from './IQSteps/Access';
import Report from './IQSteps/Report';
import Offline from './IQSteps/Offline';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { redirectToAppLogin } from '@utils/payment';
import AccessProduct from './IQSteps/AccessProduct';

function Offers() {
  const [stepNumber, setStepNumber] = useState(0);
  const { earlyAccessSalePlan, offlineAccessSalePlan, analysisReportSalePlan,  } = usePaymentConfig();
  const [isPdfReportPurchased, setPdfReportPurchased] = useState(false);

  const stepsToShow = useMemo(() => {
    const arr = [
      {
        name: 'Add\nPacks',
        component: <AddPacks setStepNumber={setStepNumber} setPdfReportPurchased={setPdfReportPurchased} />,
      },
    ];
    earlyAccessSalePlan &&
      arr.push({
        name: 'Early\nAccess',
        component: <Access setStepNumber={setStepNumber} isPdfReportPurchased={isPdfReportPurchased} />,
      });
    analysisReportSalePlan &&
      arr.push({
        name: 'PDF\nReport',
        component: <Report setStepNumber={setStepNumber} />,
      });
    offlineAccessSalePlan &&
      arr.push({
        name: 'Offline\nMode',
        component: <Offline setStepNumber={setStepNumber} />,
      });
    arr.push({ name: 'Access\nProduct', component: <AccessProduct /> });
    return arr;
  }, [earlyAccessSalePlan, offlineAccessSalePlan, analysisReportSalePlan, isPdfReportPurchased]);

  useEffect(() => {
    if (stepNumber === stepsToShow.length - 1) {
      redirectToAppLogin()
    }
  }, [stepNumber, stepsToShow, isPdfReportPurchased]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundImage: 'url(images/gradient_background.webp)',
          backgroundSize: '100% 400px',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#fff',
        }}
      >
        <SignInHeader steps={stepsToShow} stepNumber={stepNumber} />
        {stepsToShow[stepNumber].component}
      </Box>
    </>
  );
}

export default Offers;
