import styled from 'styled-components'

export const Card = styled.div`
    width: 100%;
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 5px 20px 0 #4532780d;
    color: var(--black-color);
    overflow: hidden;
`

export const Title = styled.h2`
    font-family: Avenir600;
    font-size: 20px;
    margin: 10px 0 0;
`

export const Description = styled.p`
    margin-top: 5px;
    font-family: Avenir400;
    font-size: 14px;
    color: rgba(2, 2, 2, 0.50);
`

export const Badge = styled.div<{ $color: string }>`
    max-width: max-content;
    max-height: max-content;
    font-family: Avenir600;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 8px 10px;
    background: ${p => p.$color};
    border-radius: 8px;
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    font-family: Avenir600;
    font-size: 16px;
    color: white;
    padding: 16px 20px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    background: var(--primary-color);
    margin-top: 15px;

    svg {
        pointer-events: none;
    }
`
export const DisabledButton = styled(Button)`
    background-color: var(--black-light-color);
    cursor: default;
`

export const TopWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Image = styled.img`
    width: 72px;
    height: 72px;
`
