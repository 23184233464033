import styled, { css } from 'styled-components'
import { useEffect, useState } from 'react'
import CloseIcon from '@assets/svg/webapp/close.svg'
import CreatedIcon from '@assets/svg/webapp/profile/cert_created.svg'
import { createCertificate } from '@api/requests'
import { getBaseAssetsUrl } from '@utils/webapp'
import { firebaseAuth } from '@services/firebase'
import { useUser } from '@providers/UserProvider'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../../App'
import { signOut } from 'firebase/auth'
import Spinner from '@components/WebApp/components/Spinner'

const Inner = styled.div`
    max-width: 460px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px 20px 0 0;
    padding: 43px 20px 20px;
    background: white;
    transform: translateY(100%);
    transition: all 0.3s ease;
`
const CreatedInner = styled(Inner)`
    padding: 25px 20px 20px;
`

const Wrapper = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    color: var(--black-color);

    background: #02020280;
    z-index: 100;
    transition: all 0.3s ease;

    opacity: 0;
    pointer-events: none;

    ${(props) =>
        props.open &&
        css`
            opacity: 1;
            pointer-events: initial;

            ${Inner} {
                transform: translateY(0);
            }
        `}
`

const Title = styled.span`
    font-family: Avenir600;
    font-size: 24px;
    line-height: 32.78px;
    text-align: center;
`

const CreatedTitle = styled(Title)`
    margin-top: 20px;
`

const SubTitle = styled.span`
    font-family: Avenir400;
    font-size: 16px;
    line-height: 21.86px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 20px;
    color: var(--black-50-color);
`

const CloseButton = styled.button`
    position: absolute;
    top: 13px;
    right: 10px;
    padding: 10px;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
`

const Button = styled.button`
    position: relative;
    width: 100%;
    padding: 18px 20px;
    border: 0;
    outline: none;
    font-family: Avenir600;
    font-size: 16px;
    line-height: 21.86px;
    text-align: center;
    margin-top: 20px;
    border-radius: 12px;
    background: var(--primary-color);
    color: white;
    cursor: pointer;

    &:disabled {
        color: transparent;
    }
`

const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
`

const ErrorMessage = styled.span`
    font-family: Avenir400;
    font-size: 10px;
    line-height: 13.66px;
    text-align: left;
    color: var(--red-color);
`

const Input = styled.input<{ error: boolean }>`
    font-family: Avenir500;
    padding: 15px;
    border: 1px solid var(--grey-color);
    border-radius: 12px;
    font-size: 14px;
    line-height: 19.12px;
    text-align: left;
    color: var(--black-color);
    outline: none;

    &:disabled {
        background: transparent;
    }

    &::placeholder {
        font-family: Avenir400;
        color: var(--black-light-color);
    }

    ${(props) =>
        props.error &&
        css`
            border-color: var(--red-color);
        `};
`

const Label = styled.label`
    font-family: Avenir500;
    font-size: 14px;
    line-height: 19.12px;
    text-align: left;
`

export default function CreateCertificateDrawer({
    open,
    onClose,
}: {
    open: boolean
    onClose: () => void
}) {
    const { setUser, setProfile, profile } = useUser()

    const [text, setText] = useState<string>('')

    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [loading, setLoading] = useState(false)

    const [isCertificatCreated, setCertificatCreated] = useState(false)

    const navigate = useNavigate()

    function onClick() {
        if (loading) return

        if (!text) {
            setErrorMessage('Fill in the field')
        } else {
            if (firebaseAuth.currentUser) {
                setErrorMessage(null)
                setLoading(true)

                firebaseAuth.currentUser
                    .getIdToken(true)
                    .then((token) => {
                        createCertificate(
                            firebaseAuth.currentUser?.uid ?? '',
                            text,
                            token
                        )
                            .then((response) => {
                                setProfile((prev) => {
                                    return prev
                                        ? {
                                              ...prev,
                                              certificate_path:
                                                  response.certificate_path,
                                          }
                                        : {
                                              certificate_path:
                                                  response.certificate_path,
                                          }
                                })

                                setCertificatCreated(true)
                            })
                            .catch(console.error)
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((err) => {
                        console.error(err)
                        setLoading(false)
                    })
            } else {
                navigate(Routes.SIGN_IN)
                signOut(firebaseAuth).catch(console.error)
                setUser(null)
            }
        }
    }

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [open])

    return (
        <Wrapper open={open}>
            {isCertificatCreated ? (
                <CreatedInner>
                    <CloseButton onClick={onClose}>
                        <CloseIcon />
                    </CloseButton>
                    <CreatedIcon />
                    <CreatedTitle>Certificate is ready!</CreatedTitle>
                    <SubTitle>
                        Thank you for waiting, your IQ certificate is ready and
                        waiting for you
                    </SubTitle>

                    <Button
                        onClick={() => {
                            profile?.certificate_path &&
                                window.open(
                                    getBaseAssetsUrl(profile.certificate_path),
                                    '_blank'
                                )
                        }}
                    >
                        View my certificate
                    </Button>
                </CreatedInner>
            ) : (
                <Inner>
                    {!loading && (
                        <CloseButton onClick={onClose}>
                            <CloseIcon />
                        </CloseButton>
                    )}

                    <Title>You’re almost there!</Title>
                    <SubTitle>
                        Please enter your name to proceed with downloading your
                        personalized IQ certificate
                    </SubTitle>
                    <InputWrapper>
                        <Label htmlFor="name">Name</Label>
                        <Input
                            disabled={loading}
                            error={!!errorMessage}
                            placeholder="Enter your name"
                            autoComplete="off"
                            id="name"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                        {errorMessage && (
                            <ErrorMessage>{errorMessage}</ErrorMessage>
                        )}
                    </InputWrapper>
                    <Button disabled={loading} onClick={onClick}>
                        {loading && <Spinner absolute={true} />}
                        Continue
                    </Button>
                </Inner>
            )}
        </Wrapper>
    )
}
