import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import Account from './components/Account';
import Results from './components/Results';

function AccessProduct() {
  const { isUserSignedIn } = usePaymentState();

  return isUserSignedIn ? <Results /> : <Account />;
}

export default AccessProduct;
