import { initializeApp } from 'firebase/app'

import {
    GoogleAuthProvider,
    OAuthProvider,
    setPersistence,
    signInWithRedirect,
    getAuth,
    browserSessionPersistence,
    signInWithPopup,
} from 'firebase/auth'

const firebaseConfig = {
    apiKey: 'AIzaSyCLEeOoLkvjDJk4M-O1bRCH-t_R5W3AYu4',
    authDomain:
        process.env.NODE_ENV === 'production'
            ? window.location.host
            : 'cognifi.one',
    projectId: 'cognifi',
    storageBucket: 'cognifi.appspot.com',
    messagingSenderId: '199045182476',
    appId: '1:199045182476:web:8310c40b2169392a1f6a29',
    measurementId: 'G-ECL47P3XKN',
}

const app = initializeApp(firebaseConfig)

export const firebaseAuth = getAuth(app)

async function signInWithRedirectToApp(provider: any) {
    await setPersistence(firebaseAuth, browserSessionPersistence)
    if (process.env.NODE_ENV === 'development') {
        return signInWithPopup(firebaseAuth, provider).catch((error) => {
            console.error('firebase sign in error', error)
        })
    }
    return signInWithRedirect(firebaseAuth, provider).catch((error) => {
        console.error('firebase sign in error', error)
    })
}

export const signInWithGoogle = async () => {
    try {
        const provider = new GoogleAuthProvider()
        provider.addScope('profile')
        provider.addScope('email')
        provider.setCustomParameters({ prompt: 'select_account' })
        return await signInWithRedirectToApp(provider)
    } catch (err) {
        return Promise.reject(err)
    }
}

export const signInWithApple = async () => {
    try {
        const provider = new OAuthProvider('apple.com')
        provider.addScope('email')
        provider.addScope('name')
        return await signInWithRedirectToApp(provider)
    } catch (err) {
        return Promise.reject(err)
    }
}
