import { Box, Typography } from '@mui/material';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { useEffect, useState } from 'react';

function QuizTimer() {
  const { quizData } = useQuizState();
  const [timer, setTimer] = useState<string | null>(null);

  useEffect(() => {
    if (quizData.startDate !== null) {
      const updateTimeLeft = () => {
        const ONE_DAY_IN_SECONDS = 24 * 60 * 60 * 1000;
        const ONE_HOUR_IN_SECONDS = 60 * 60 * 1000;
        const ONE_MINUTE_IN_SECONDS = 60 * 1000;
        if (quizData.startDate) {
          const timeLeftInSeconds = Date.now() - quizData.startDate;
          const daysLeft = Math.trunc(timeLeftInSeconds / ONE_DAY_IN_SECONDS);
          const hoursLeft = Math.trunc((timeLeftInSeconds - daysLeft * ONE_DAY_IN_SECONDS) / ONE_HOUR_IN_SECONDS);
          const minutesLeft = Math.trunc(
            (timeLeftInSeconds - daysLeft * ONE_DAY_IN_SECONDS - hoursLeft * ONE_HOUR_IN_SECONDS) /
              ONE_MINUTE_IN_SECONDS
          );
          const secondsLeft = Math.trunc(
            (timeLeftInSeconds -
              daysLeft * ONE_DAY_IN_SECONDS -
              hoursLeft * ONE_HOUR_IN_SECONDS -
              minutesLeft * ONE_MINUTE_IN_SECONDS) /
              1000
          );
          const minutes = minutesLeft ? ('0' + minutesLeft).slice(-2) : '00';
          const seconds = secondsLeft ? ('0' + secondsLeft).slice(-2) : '00';

          setTimer(`${minutes} : ${seconds}`);
        }
      };

      const intervalId = setInterval(() => {
        updateTimeLeft();
      }, 1000);

      updateTimeLeft();

      return () => clearInterval(intervalId);
    }
  }, [quizData.startDate]);

  return (
    <Box
      sx={{
        position: 'absolute',
        padding: '0 10px',
        background: '#FFF',
      }}
    >
      <Box
        sx={{
          border: '1px solid #E6E7EB',
          borderRadius: '24px',
          padding: '5px 12px',
          background: '#fff',
          width: 'max-content',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir500',
            fontSize: '12px',
            color: '020202',
            userSelect: 'none',
            display: 'flex',
            gap: '3px',
            alignItems: 'center',
          }}
        >
          <img src={'/cognifi/svg/stopwatch.svg'} alt="stopwatch"></img>
          {timer}
        </Typography>
      </Box>
    </Box>
  );
}

export default QuizTimer;
